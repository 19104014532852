import React, { useSyncExternalStore } from 'react';
import { useState, useEffect } from 'react';
import UserService from '../../services/user.service';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import columns from './fInvoice';
import Select from 'react-select';
import AuthService from "../../services/auth.service";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import pColumns from './pPlan';
import Checkbox from '@mui/material/Checkbox';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';


const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , width: '270px', minHeight: '20px', fontSize: 'medium', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)'}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '101%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , width: '150px', minHeight: '20px', fontSize: 'medium', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)'}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '101%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

function getweekNo(d) {
    d = new Date(+d);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    var yearStart = new Date(d.getFullYear(), 0, 1);
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
    return [d.getFullYear(), weekNo];
}
  
function weeksInYear(year) {
    var month = 11,
        day = 31,
        week;
    do {
        let d = new Date(year, month, day--);
        week = getweekNo(d)[1];
    } while (week == 1);

    return week;
}

const FleetInvoice = () => {
    const [ regOptions, setRegOptions ] = useState([]);
    const [ associateOptions, setAssociateOptions ] = useState([]);
    const [ displayData, setDisplayData ] = useState([]);
    const [ rateCard, setRateCard ] = useState([]);
    const [ colPlc, setColPlc ] = useState();
    const [ descriptionOptions, setDescriptionOptions ] = useState([])
    const [ field, setField ] = useState()
    const [ skNo, setSkNo ] = useState()
    const [ address1, setAddress1 ] = useState()
    const [ address2, setAddress2 ] = useState()
    const [ town, setTown ] = useState()
    const [ countyAPost, setCountyAPost ] = useState()
    const [ phone, setPhone ] = useState()
    const [ email, setEmail ] = useState()
    const [ name, setName ] = useState('Select...')
    const [ reg, setReg ] = useState()
    const [ associates, setAssociates ] = useState([])
    const [ weekOption, setWeekOption ] = useState([])
    const [ week, setWeek ] = useState()
    const [ bSubtotal, setBSubtotal ] = useState()
    const [ bVat, setBVat ] = useState()
    const [ bTotal, setBTotal ] = useState()
    const [ paidWeek, setPaidWeek ] = useState()
    const [ depot, setDepot ] = useState()
    const [ col, setCol ] = useState([])
    const [ uploadCheck, setUploadCheck ] = useState()
    const [ bRow, setBRow ] = useState()
    const [ invoiceNo, setInvoiceNo ] = useState()
    const [ openDel, setOpenDel ] = useState(false)
    const [ pCheck, setPcheck ] = useState(false)
    const [ numberOfPayments, setNumberOfPayments ] = useState()
    const [ noOfWeeks, setNoOfWeeks ] = useState([])
    const [ displayDataPay, setDisplayDataPay ] = useState([])
    const [ startOn, setStartOn ] = useState()
    const [ lastUpdateDateTime, setLastUpdateDateTime ] = useState()
    const [ lastUpdateBy, setLastUpdateBy ] = useState()
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [ openAdd, setOpenAdd ] = useState(false)
    const [ pictureCheck, setPictureCheck ] = useState(false)

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div style={{display: 'inline', width: '300px', height: '300px', margin: '5px', border: '1px solid #edebeb', borderRadius: '5px', padding: '5px'}}>
                    <div className="flex spaceing">
                        <div className="flexComp">
                            <label for="dlNo" className="label3"><b>Name</b></label>
                            <Select 
                                options={associateOptions}
                                styles={colourStyles}
                                value={{label: name, value: name}}
                                onChange={e => handleName(e.value)}
                            /> 
                        </div>
                    </div>
                    <p style={{marginLeft: '5px', marginTop: '5px'}}>{skNo}</p>
                    <p style={{marginLeft: '5px', marginTop: '5px'}}>{address1} <br></br>{address2} <br></br>{town} <br></br>{countyAPost}</p>
                    <div className="flex " style={{marginTop: '-15px'}}>
                        <div className="flexComp">
                            <label for="dlNo" className="label3"><b>Reg</b></label>
                            <Select 
                                options={regOptions}
                                styles={colourStyles}
                                value={{label: reg, value: reg}}
                                onChange={e => setReg(e.value)}
                            /> 
                        </div>
                    </div>
                    
                </div>
                <div style={{display: 'inline', width: '300px', height: '300px', margin: '5px', border: '1px solid #edebeb', borderRadius: '5px', padding: '10px'}}>
                    <p><b>Invoice No</b>&nbsp;&nbsp;&nbsp; {skNo && week ? week.substring(0, 4)+week.substring(week.length - 2)+'-'+skNo?.substring(skNo.length - 6)+'.4' : ''}</p>
                    <p style={{marginTop: '-10px'}}>
                        <b>Week no</b>
                        <Select 
                            options={weekOption}
                            styles={colourStyles}
                            value={{label: week, value: week}}
                            onChange={e => setWeek(e.value)}
                        /> 
                    </p>
                    <p style={{marginTop: '-10px'}}>
                        <b>Paid on week</b>
                        <Select 
                            options={weekOption}
                            styles={colourStyles}
                            value={{label: paidWeek, value: paidWeek}}
                            onChange={e => {
                                setPaidWeek(e.value)
                                setStartOn(e.value)
                            }}
                        /> 
                    </p>
                    <p style={{marginTop: '-10px'}}><b>Date</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {new Date().toLocaleDateString('en-GB')}</p>
                    <p style={{marginTop: '-15px'}}><b>Due</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {new Date().toLocaleDateString('en-GB')}</p>
                    <p style={{marginTop: '-15px'}}><b>Vat</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 20%</p>
                    <p style={{marginTop: '-15px'}}><b>Contact</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{phone}</p>
                    <p style={{marginTop: '-15px'}}><b>Email</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {email}</p>
                </div>
                <div style={{display: 'inline-block'}}>
                    <div style={{ width: '300px', height: '150px', margin: '5px', marginBottom: '-10px', border: '1px solid #edebeb', borderRadius: '5px', padding: '10px'}}>
                        <p style={{marginTop: '-5px', fontSize: '20px', borderBottom: '1px solid #edebeb'}}><b>Sub Total</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{bSubtotal}</p>
                        <p style={{marginTop: '-5px', fontSize: '20px', borderBottom: '1px solid #edebeb'}}><b>VAT</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{bVat}</p>
                        <p style={{marginTop: '-5px', fontSize: '20px', borderBottom: '1px solid #edebeb'}}><b>Total</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{bTotal}</p>
                    </div>
                    <p style={{marginTop: '10px'}}><i><b>Last upload</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {lastUpdateDateTime}</i></p>
                    <p style={{marginTop: '-10px'}}><i><b>by</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {lastUpdateBy}</i></p>
                   
                        Pictures<Checkbox checked={pictureCheck} onChange={handlePictureChange} /><br></br>
                        Payment plan<Checkbox checked={pCheck} onChange={handlePlanChange} />
                    <button class="buttonSkOn" style={{marginBottom: '15px', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={upload} >
                        <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Upload&nbsp;&nbsp;{uploadCheck == false ? <CircularProgress color='inherit' size={20} sx={{marginTop: 1, marginBottom: 0}}/> : uploadCheck == true ? <CheckRoundedIcon color='inherit' size={20} sx={{marginTop: 0, marginBottom: 0}}/> : ''}</span>
                        <div class="fill-container"></div>
                    </button>
                </div>
                {/*<GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <GridToolbarQuickFilter />*/}
            </GridToolbarContainer>
        );
    }

    function CustomToolbarPPlan() {
        return (
            <GridToolbarContainer>  
                <div className="flex " style={{marginTop: '5px', marginLeft: '5px', marginBottom: '5px'}}>
                    <div className="flex">
                        <label for="dlNo" className="label3" style={{marginRight: '10px', marginLeft: '10px',}}><b>No of weeks</b></label>
                        <Select 
                            options={noOfWeeks}
                            styles={colourStylesS}
                            sx={{marginLeft: '10px', marginRight: '15px'}}
                            value={{label: numberOfPayments, value: numberOfPayments}}
                            onChange={e => handleNoPay(e)}
                        /> 
                    </div>
                    <div className="flex">
                        <label for="dlNo" className="label3" style={{marginRight: '10px', marginLeft: '20px',}}><b>Start On</b></label>
                        <Select 
                            options={weekOption}
                            styles={colourStylesS}
                            sx={{marginLeft: '10px', marginRight: '15px'}}
                            value={{label: startOn ? startOn : paidWeek, value: startOn ? startOn : paidWeek}}
                            onChange={e => setStartOn(e.value)}
                        /> 
                    </div>
                </div>
                {/*<GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <GridToolbarQuickFilter />*/}
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        let plCol = columns
        plCol.push({
            field: 'delete',
            headerName: '',
            headerClassName: 'table-header',
            width: 50,
            editable: false,
            renderCell: (param) =>(
                    param.row.description ? <>
                        <DeleteOutlineIcon onClick={e => deleteRow(param.row)} />
                    </> : ''
            )
        })
        UserService.getFleetRates().then(
            response =>{
                const data = response.data;
                setRateCard(response.data)
                let plc = []
                data.map(row =>{
                    if(row.description){
                        plc.push(row.description)
                    }
                })
                plc = [...new Set(plc)]
                columns.map(row =>{
                    if( row.field === 'description'){
                        row.type = 'singleSelect';
                        row.valueOptions = plc
                    }
                })
                setColPlc(columns)
            }
        ).catch(error => console.log(error))    

        UserService.getVanRegs().then(
            response =>{
                const data = response.data;
                let plc = [];
                data?.map(row =>{
                    plc.push({value: row.reg, label: row.reg})
                })
                setRegOptions(plc)
            }
        ).catch(err => console.log(err))

        UserService.getActiveAssociates().then(
            response =>{
                const data = response.data;
                console.log(data)
                setAssociates(data)
                let plc = [];
                data?.map(row =>{
                    plc.push({value: row.name, label: row.name})
                })
                setAssociateOptions(plc)
            }
        ).catch(error => console.log(error))

        let plc = [];
        for(let i = 0; i < 15; i++){
            plc.push({id: '#'+(i+1), description: '', rate: '', qty: '', subtotal: '', vat: '', total: ''})
        }
        setDisplayData(plc)
        const weekCount = weeksInYear(new Date().getFullYear())
        let weekPlc = []
        for(let i = 1; i <= weekCount; i++){
            weekPlc.push({value: new Date().getFullYear()+'-'+i.toString().padStart(2, '0'), label: new Date().getFullYear()+'-'+i.toString().padStart(2, '0')})
        }
        setWeekOption(weekPlc)

        let wPlc = []
        for(let i = 1; i <= 100 ; i++){
            wPlc.push({value: i, label: i})
        }
        setNoOfWeeks(wPlc)
    },[])

    useEffect(()=>{
        
        if(week && name){
            setInvoiceNo(week?.substring(0, 4)+week?.substring(week?.length - 2)+'-'+skNo?.substring(skNo?.length - 6)+'.4')
            const dataSend = {document_no: week.substring(0, 4)+week.substring(week.length - 2)+'-'+skNo?.substring(skNo.length - 6)+'.4'}
            UserService.getFleetPartials(dataSend).then(
                response =>{
                    const data = response.data
                    console.log(data)
                    let dis = displayData
                    let sub = 0
                    let vat = 0
                    let tot = 0
                    if(data.length > 0){
                        setDisplayData([])
                        dis.map((row, index) =>{
                            if(data[index] && data[index].category){
                                row.document_no = data[index].document_no
                                row.category = data[index].category
                                row.description = data[index].description
                                row.rate = data[index].rate
                                row.subtotal = data[index].sub_total
                                row.qty = data[index].qty
                                row.vat = data[index].vat
                                row.total = data[index].total
                                row.vatPull = data[index].vat_pull

                                sub += Number(data[index].sub_total.substring(1, data[index].sub_total.length))
                                vat += Number(data[index].vat.substring(1, data[index].vat.length))
                                tot += Number(data[index].total.substring(1, data[index].total.length))
                            }else{
                                row.document_no = ''
                                row.category = ''
                                row.description = ''
                                row.rate = ''
                                row.subtotal = ''
                                row.qty = ''
                                row.vat = ''
                                row.total = ''
                                row.vatPull = ''
                            }
                        })
                        setBSubtotal('£'+sub.toFixed(2))
                        setBVat('£'+vat.toFixed(2))
                        setBTotal('£'+tot.toFixed(2))
                        setDisplayData(dis)
                    }else{
                        let plc = []
                        const len = displayData.length
                        for(let i = 1; i <= len; i++){
                            plc.push({id: '#'+i, category: '', description: '', rate: '', qty: '', subtotal: '', vat: '', total: '', vatPull: ''})
                        }
                        setBSubtotal('')
                        setBVat('')
                        setBTotal('')
                        setDisplayData(plc)
                    }
                    
                }
            )
        }
    },[name, week])

    useEffect(()=>{
        if(invoiceNo){
            const dataSend = {document_no: invoiceNo}
            UserService.lastUpdate(dataSend).then(
                response =>{
                    const data = response.data
                    console.log(data)
                    if(data){
                        setLastUpdateBy(data.updatedBy)
                        setLastUpdateDateTime(data.updatedAt)
                    }else{
                        setLastUpdateBy('No upload done')
                        setLastUpdateDateTime('No upload done')
                    }
                }
            ).catch(err => console.log(err))
        }
    },[invoiceNo])

    const handleDescription = (params) => {
        let data = displayData
        if(field == 'description'){
            rateCard.map(row =>{
                if(params.description == row.description){
                    params.document_no = invoiceNo
                    params.rate = row.price
                    params.category = row.category
                    params.vatPull = row.vat
                    params.subtotal = ''
                    params.qty = ''
                    params.vat = ''
                    params.total = ''
                }
            })
        }
        if(field == 'qty'){
            params.subtotal = '£'+(Number(params.rate.substring(1, params.rate.length))*params.qty).toFixed(2) 
            rateCard.map(row =>{
                if(params.description == row.description){
                    if(row.vat == 'Yes'){
                        params.vat = '£'+((Number(params.subtotal.substring(1, params.subtotal.length))*20)/100).toFixed(2) 
                        params.total = '£'+(Number(params.subtotal.substring(1, params.subtotal.length))+Number(params.vat.substring(1, params.vat.length))).toFixed(2) 
                    }else{
                        params.vat = '£0.00'
                        params.total = '£'+Number(params.subtotal.substring(1, params.subtotal.length)).toFixed(2) 
                    }
                }
            })
        }
        if(field == 'rate'){
            rateCard.map(row =>{
                if(params.description == row.description){
                    params.subtotal = ''
                    params.qty = ''
                    params.vat = ''
                    params.total = ''
                }
            })
        }
        data.map(row =>{
            if(row.id == params.id){
                row.description = params.description
                row.category = params.category
                row.rate = params.rate
                row.qty = params.qty
                row.subtotal = params.subtotal
                row.vat = params.vat
                row.total = params.total
                row.vatPull = params.vatPull
            }
        })
        let subtot = 0
        let vat = 0
        let tot = 0
        data.map(row =>{
            subtot += Number(row.subtotal.substring(1, row.subtotal.length))
            vat += Number(row.vat.substring(1, row.vat.length))
            tot += Number(row.total.substring(1, row.total.length))
        })
        setBSubtotal('£'+subtot.toFixed(2) )
        setBVat('£'+vat.toFixed(2) )
        setBTotal('£'+tot.toFixed(2) )
        setDisplayData(data)
        const partialSend = {
            document_no: week.substring(0, 4)+week.substring(week.length - 2)+'-'+skNo?.substring(skNo.length - 6)+'.4',
            category: params.category,
            description: params.description,
            rate: params.rate,
            qty: params.qty,
            sub_total: params.subtotal,
            vat: params.vat,
            total: params.total,
            vat_pull: params.vatPull
        }
        UserService.fleetPartialsUpdate(partialSend).then(
            response =>{
                
                console.log(response.data)
            }
        ).catch(err => console.log(err))
        return params
    }

    const handleName = (name) =>{
        setName(name)
        associates?.map(row =>{
            if(row.name == name){
                setSkNo(row.account_no)
                setAddress1(row.address1)
                setAddress2(row.address2)
                setTown(row.town)
                setCountyAPost(row.county+', '+row.post_code)
                setPhone(row.phone)
                setEmail(row.email)
                setDepot(row.station)
            }
        })
    }

    const  handlePlanChange = (e) =>{
         setPcheck(e.target.checked)
         if(!e.target.checked){
            setDisplayDataPay([])
         }
         console.log(e.target.checked)
    }
    const handlePictureChange = (e) =>{
        setOpenAdd(e.target.checked)
        setPictureCheck(e.target.checked)
    }

    const handleNoPay = (e) =>{
        setNumberOfPayments(e.value)
        const pay = e.value
        let plc =[]
        const weeksInYearT = weeksInYear(startOn.split('-')[0])
        console.log(weeksInYearT)
        if(bTotal){
            let tw = 0
            for(let i = 1; i <= pay; i++){
                console.log(i-1+'----'+weeksInYearT)
                if(i-1+Number(startOn.split('-')[1]) > weeksInYearT){
                    tw += 1
                }
                plc.push({
                    id: i, 
                    description: `Payment ${i} of ${pay} (${invoiceNo}, ${reg})`, rate: '£'+Number(bTotal.substring(1, bTotal.length))/pay, week_no: tw < 1 ? startOn.split('-')[0]+'-'+(Number(startOn.split('-')[1])+i-1).toString().padStart(2, '0') : Number(startOn.split('-')[0])+1+'-'+tw.toString().padStart(2, '0')})
            }
        }
        setDisplayDataPay(plc)
    }

    useEffect(()=>{
        const pay = numberOfPayments
        let plc =[]
        const weeksInYearT = weeksInYear(startOn?.split('-')[0])
        
        if(bTotal){
            let tw = 0
            for(let i = 1; i <= pay; i++){
                console.log(i-1+'----'+weeksInYearT)
                if(i-1+Number(startOn.split('-')[1]) > weeksInYearT){
                    
                    tw += 1
                }
                plc.push({
                    id: i, 
                    description: `Payment ${i} of ${pay} (${invoiceNo}, ${reg})`, rate: '£'+Number(bTotal.substring(1, bTotal.length))/pay, week_no: tw < 1 ? startOn.split('-')[0]+'-'+(Number(startOn.split('-')[1])+i-1).toString().padStart(2, '0') : Number(startOn.split('-')[0])+1+'-'+tw.toString().padStart(2, '0')})
            }
        }
        setDisplayDataPay(plc)
    }, [startOn])

    const onFileChange = (event) => {
        // Update the state
        setSelectedFiles(event.target.files);
    };
    
    const fileData = () => {
        if (selectedFiles.length > 0) {
            return (
                <div>
                    {Array.from(selectedFiles).map((file, index) => (
                        <div key={index}>
                            <p>File Name: {file.name}</p>
                            {/*<p>File Type: {file.type}</p>
                            <p>Last Modified: {file.lastModifiedDate.toDateString()}</p>*/}
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h4>Choose before Pressing the Upload button</h4>
                </div>
            );
        }
    };

    const handleCloseAdd = (e) =>{
        setSelectedFiles([])
        setOpenAdd(false)
        setPictureCheck(false)
    }

    const pictureUpload = () =>{
        setOpenAdd(false)
    }

    const deleteRow = (row) =>{
        setOpenDel(true)
        setBRow(row)
        /**/
    }

    const deleteR = () =>{
        const dataSend = {document_no: bRow?.document_no, description: bRow?.description, total: bRow?.total}
        UserService.deleteFleetPartial(dataSend).then(
            response =>{
                let plc = displayData
                plc = plc.filter(row1 =>{
                    return row1.description !== bRow.description && row1.description !== ''
                })
                console.log(plc)
                let plC = []
                let pSub = 0
                let pVat = 0 
                let pTot = 0
                for(let i = 0; i < 15; i++){
                    if(i < plc.length){
                        plC.push({id: '#'+(i+1), document_no: invoiceNo, category: '', vatPull: '', description: plc[i].description, rate: plc[i].rate, qty: plc[i].qty, subtotal: plc[i].subtotal, vat: plc[i].vat, total: plc[i].total})
                        pSub += Number(plc[i].subtotal.substring(1, plc[i].subtotal.length))
                        pVat += Number(plc[i].vat.substring(1, plc[i].vat.length))
                        pTot += Number(plc[i].total.substring(1, plc[i].total.length))
                    }else{
                        plC.push({id: '#'+(i+1), document_no: '', category: '', vatPull: '', description: '', rate: '', qty: '', subtotal: '', vat: '', total: ''})
                    }
                    
                }   
                setBSubtotal('£'+pSub.toFixed(2))
                setBVat('£'+pVat.toFixed(2))
                setBTotal('£'+pTot.toFixed(2))
                setDisplayData(plC)
                setOpenDel(false)
            }
        ).catch(err => console.log(err))
    }
    
    const upload = () =>{
        setUploadCheck(false)
        let dataSend = []
        let dataPlc = {}
        let formData = new FormData();
        if(displayDataPay.length < 1){
            dataSend = [
                {
                    document_no: week.substring(0, 4)+week.substring(week.length - 2)+'-'+skNo?.substring(skNo.length - 6)+'.4', 
                    category: 'FL-CHARGE',
                    sk_no: skNo,
                    associate_name: name,
                    schedule_date: new Date().getFullYear()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
                    description: 'Invoice '+week.substring(0, 4)+week.substring(week.length - 2)+'-'+skNo?.substring(skNo.length - 6)+'.4',
                    rate: '-'+bTotal.substring(1, bTotal.length),
                    qty: 1,
                    sub_total: '-'+bTotal.substring(1, bTotal.length),
                    week_no: paidWeek,
                    station: depot,
                    vat: 'No',
                    updatedBy: AuthService.getCurrentUser().username
                }
            ]
        }else{
            dataSend = [
                {
                    document_no: week.substring(0, 4)+week.substring(week.length - 2)+'-'+skNo?.substring(skNo.length - 6)+'.4', 
                    category: 'FL-CHARGE',
                    sk_no: skNo,
                    associate_name: name,
                    schedule_date: new Date().getFullYear()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
                    description: 'Invoice '+week.substring(0, 4)+week.substring(week.length - 2)+'-'+skNo?.substring(skNo.length - 6)+'.4',
                    rate: '-'+bTotal.substring(1, bTotal.length),
                    qty: 1,
                    sub_total: '-'+bTotal.substring(1, bTotal.length),
                    week_no: '',
                    station: depot,
                    vat: 'No',
                    updatedBy: AuthService.getCurrentUser().username
                }
            ]
            displayDataPay.map(row =>{
                dataSend.push({
                    document_no: week.substring(0, 4)+week.substring(week.length - 2)+'-'+skNo?.substring(skNo.length - 6)+'.4', 
                    category: 'FL-PP',
                    sk_no: skNo,
                    associate_name: name,
                    schedule_date: new Date().getFullYear()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
                    description: row.description,
                    rate: '-'+row.rate.substring(1, row.rate.length),
                    qty: 1,
                    sub_total: '-'+row.rate.substring(1, row.rate.length),
                    week_no: row.week_no,
                    station: depot,
                    vat: 'No',
                    updatedBy: AuthService.getCurrentUser().username
                })
            })
        }
        displayData?.map(row =>{
            let category = ''
            rateCard.map(el =>{
                if(row.description == el.description){
                    category = el.category
                }
            })
            if(row.description){
                dataSend.push(
                    {
                        document_no: week.substring(0, 4)+week.substring(week.length - 2)+'-'+skNo?.substring(skNo.length - 6)+'.4', 
                        category: category,
                        sk_no: skNo,
                        associate_name: name,
                        schedule_date: new Date().getFullYear()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
                        description: row.description,
                        rate: '-'+row.rate.substring(1, row.rate.length),
                        qty: row.qty,
                        sub_total: '-'+row.qty*Number(row.rate.substring(1, row.rate.length)),
                        week_no: '',
                        station: depot,
                        vat: row.vatPull,
                        updatedBy: AuthService.getCurrentUser().username
                    }
                )
            }
        })
        
        formData.append('data', JSON.stringify(dataSend))
        formData.append('name', name)
        formData.append('sk_no', skNo)
        formData.append('address1', address1)
        formData.append('address2', address2)
        formData.append('town', town)
        formData.append('countyAPost', countyAPost)
        formData.append('reg', reg)
        formData.append('invoiceNo', invoiceNo)
        formData.append('week', week)
        formData.append('date', new Date().toLocaleDateString('en-GB'))
        formData.append('due', new Date().toLocaleDateString('en-GB'))
        formData.append('vat', '20.00%')
        formData.append('issuedBy', AuthService.getCurrentUser().username)
        formData.append('phone', phone)
        formData.append('email', email)

        if(selectedFiles.length > 0 && pictureCheck == true){
            for(let i = 0; i < selectedFiles.length; i++){
                formData.append(`file${i}`, selectedFiles[i])
            }
        }
          
        UserService.fleetInvoiceUpdate(formData).then(
            response =>{
                setUploadCheck(true)
                console.log(response)
                const dataSend1 = {document_no: invoiceNo}
                UserService.lastUpdate(dataSend1).then(
                    response =>{
                        const data = response.data
                        console.log(data)
                        if(data){
                            setLastUpdateBy(data.updatedBy)
                            setLastUpdateDateTime(data.updatedAt)
                        }else{
                            setLastUpdateBy('No upload done')
                            setLastUpdateDateTime('No upload done')
                        }
                    }
                ).catch(err => console.log(err))
            }
        ).catch(err => console.log(err))
    }

    return(
        <div className='dashArch'>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Fleet Invoice</h3>
            <div className='flex' >
                <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                    <StripedDataGridPro
                        pagination
                        rows={displayData}
                        columns={colPlc ? colPlc : []}
                        initialState={{
                            pagination: {
                            paginationModel: {
                                pageSize: 100,
                            },
                            },
                        }}
                        sx={{fontSize: '14px', fontFamily: '',  [`.${gridClasses.cell}.cold`]: {
                            backgroundColor: '#D35858',
                            color: '#1a3e72',
                        },
                        [`.${gridClasses.cell}.hot`]: {
                            backgroundColor: '#A6D358',
                            color: '#1a3e72',
                        },}}
                        pageSizeOptions={[100]}
                        slots={{
                            loadingOverlay: LinearProgress,
                            toolbar: CustomToolbar
                        }}
                        //loading={loading}
                        //{...data}
                        processRowUpdate={params => handleDescription(params)}
                        onCellEditStop={(params) => setField(params.field)}
                        onProcessRowUpdateError={err => console.log(err)}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                        columnVisibilityModel={{
                            category: false,
                            vatPull: false,
                            document_no: false
                        }}
                        getCellClassName={(params) => {
                            if (params.field === 'city' || params.value == null) {
                            return '';
                            }
                            return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                        }}
                    />
                </Box> 
                {pCheck ?
                <Box sx={{ height: 'fit-content', width: 'fit-content', marginLeft: '1%', marginTop:2 }}>
                    <StripedDataGridPro
                        pagination
                        rows={displayDataPay}
                        columns={pColumns  ? pColumns : []}
                        initialState={{
                            pagination: {
                            paginationModel: {
                                pageSize: 100,
                            },
                            },
                        }}
                        sx={{fontSize: '14px', fontFamily: '',  [`.${gridClasses.cell}.cold`]: {
                            backgroundColor: '#D35858',
                            color: '#1a3e72',
                        },
                        [`.${gridClasses.cell}.hot`]: {
                            backgroundColor: '#A6D358',
                            color: '#1a3e72',
                        },}}
                        pageSizeOptions={[100]}
                        slots={{
                            loadingOverlay: LinearProgress,
                            toolbar: CustomToolbarPPlan
                        }}
                        //loading={loading}
                        //{...data}
                        processRowUpdate={params => handleDescription(params)}
                        onCellEditStop={(params) => setField(params.field)}
                        onProcessRowUpdateError={err => console.log(err)}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                        columnVisibilityModel={{
                            category: false,
                            vatPull: false,
                            document_no: false
                        }}
                        getCellClassName={(params) => {
                            if (params.field === 'city' || params.value == null) {
                            return '';
                            }
                            return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                        }}
                    />
                </Box> : ''
                } 
            </div>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openDel}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >This will only delete partial data</label></DialogTitle>
                    <DialogContent>
                      <p>*to delete entrances on the payment database you have to do a reupload after you delete the partial data</p>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={deleteR} >
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Delete</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={e => setOpenDel(false)}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
    <Dialog
        //fullScreen
        open={openAdd}
        //TransitionComponent={Transition}
        //keepMounted
        //onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle><label for="select" className="label" >Add Pictures</label></DialogTitle>
        <DialogContent>
            <div style={{marginRight: '5%'}}>
                {/* ...existing code... */}
                <div style={{marginLeft: '2%', marginTop: '20px'}}>
                    <label for="dlEx" className="label2">Upload Pictures</label>
                    <div className="flexAssets">
                        <div className="inlineLnx">
                            <div className="attach">
                                <div>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={onFileChange}
                                    />
                                </div>
                                {fileData()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={pictureUpload}>
                <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Upload</span>
                <div class="fill-container"></div>
            </button>
            <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseAdd}>
                <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                <div class="fill-container"></div>
            </button>
        </DialogActions>
    </Dialog>
</React.Fragment>
        </div>
    )
}
export default FleetInvoice;