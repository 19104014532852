import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import { alpha, useTheme } from '@mui/material/styles';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import recColumns from "./rec";
import refColumns from "./refresher";
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from 'react-select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js';

const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%', minHeight: '20px', fontSize: 'x-large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)'}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '101%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const Recruitment = (props) =>{
    const [ interData, setInterData ] = useState([])
    const [ openNew, setOpenNew ] = useState(false)
    const [ date, setDate ] = useState()
    const [ drivers, setDrivers ] = useState([])
    const [ driver, setDriver ] = useState()
    const [ associatesData, setAssociatesData ] = useState([])
    const [ depots, setDepots ] = useState([])
    const [ depot, setDepot ] = useState()
    const [ age, setAge ] = useState()
    const [ score, setScore ] = useState()
    const [ refDisplay, setRefDisplay ] = useState([])

    function CustomToolbarA() {
        return (
            <GridToolbarContainer sx={{fontSize: '16px', marginTop: 0}}>
                <div className="inlines">
                    <h6 style={{marginLeft: '-280px'}}>Interviews Booked</h6>
                    <div>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }

    function CustomToolbarB() {
        return (
            <GridToolbarContainer sx={{fontSize: '16px', marginTop: 0}}>
                <div className="inlines">
                    <h6 style={{marginLeft: '-280px'}}>DA Training Refresher</h6>
                    <div>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                        <Button className="incGridButton" onClick={e => setOpenNew(true)}> 
                            <LibraryAddOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  NEW ENTRY
                        </Button>
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }

    useEffect(() =>{
        UserService.getUserDetails().then(
            response =>{
                const data = response.data
                let dataPlc = []
                data.map(dat =>{
                    dataPlc.push({value: dat.name, label: dat.name})
                })
                setAssociatesData(data)
                setDrivers(dataPlc)
            }
        ).catch(err =>console.log(err))
    },[])

    useEffect(()=>{
        const data = props.interviews
        let dataPlc = []
        data.map(dat =>{
            if(dat.station == depot){
                dataPlc.push(dat)
            }
        })
        setInterData(dataPlc)
    },[props.interviews])

    useEffect(() =>{
        setDepots(props.depots)
    }, [props.depots])

    useEffect(()=>{
        UserService.getRefresher({depot: props.depot}).then(
            response =>{
                const data = response.data
                const dataPlc = []
                data?.map(dat =>{
                    dataPlc.push({id: dat.entry, name: dat.name, date: dat.date, score: dat.score, age: dat.age})
                })
                setRefDisplay(dataPlc)
            }
        )
    },[props.depot])

    useEffect(() =>{
        console.log(refDisplay)
    },[refDisplay])

    const handleDriver = (e) =>{
        setDriver(e.value)
        associatesData?.map(ass =>{
            if(ass.name == e.value){
                setAge(ass.age)
            }
        })
    }

    const createEntry = () =>{
        let dataSend = {depot: depot, name: driver, date: date, score: score, age: age}
        UserService.createRefresher(dataSend).then(
            response =>{
                window.location.reload(false)
            }
        ).catch(err => console.log(err))
    }

    return(
        <div className="dashArch">
            <div className="gridCont">
                {interData.length > 0 ? <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%'}}>
                    <StripedDataGridPro
                        pagination
                        rows={interData}
                        columns={recColumns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                            backgroundColor: '#D35858',
                            color: '#1a3e72',
                        },
                        [`.${gridClasses.cell}.hot`]: {
                            backgroundColor: '#A6D358',
                            color: '#1a3e72',
                        },}}
                        pageSizeOptions={[50, 100, 200]}
                        slots={{
                            loadingOverlay: LinearProgress,
                            toolbar: CustomToolbarA
                        }}
                        slotProps={{ toolbar: { csvOptions: { fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] } } }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        getCellClassName={(params) => {
                            if (params.field === 'city' || params.value == null) {
                                return '';
                            }
                            return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                        }}
                    />
                </Box> : ''}
                <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%'}}>
                    <StripedDataGridPro
                        pagination
                        rows={refDisplay}
                        columns={refColumns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                            backgroundColor: '#D35858',
                            color: '#1a3e72',
                        },
                        [`.${gridClasses.cell}.hot`]: {
                            backgroundColor: '#A6D358',
                            color: '#1a3e72',
                        },}}
                        pageSizeOptions={[50, 100, 200]}
                        slots={{
                            loadingOverlay: LinearProgress,
                            toolbar: CustomToolbarB
                        }}
                        slotProps={{ toolbar: { csvOptions: { fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] } } }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        getCellClassName={(params) => {
                            if (params.field === 'city' || params.value == null) {
                                return '';
                            }
                            return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                        }}
                    />
                </Box>
            </div>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openNew}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Add entry</label></DialogTitle>
                    <DialogContent>
                        <div className="flex spaceing">
                            <div className="flexComp">
                                <label for="dlNo" className="label2">Depot</label>
                                <Select 
                                    options={depots}
                                    styles={colourStyles}
                                    value={{label: depot, value: depot}}
                                    onChange={e => setDepot(e.value)}
                                /> 
                            </div>
                        </div>
                        <div className="flex spaceing">
                            <div className="flexComp">
                                <label for="dlEx" className="label2">Driver</label>
                                <Select 
                                    options={drivers}
                                    styles={colourStyles}
                                    value={{label: driver, value: driver}}
                                    onChange={e => handleDriver(e)}
                                /> 
                            </div>
                            <div className="flexComp ">
                                <label for="origin" className="label2 correction">Date</label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                        label="pick a date" 
                                        format="DD/MM/YYYY"
                                        slotProps={{
                                            textField: {
                                            sx: {
                                                //marginTop: '10%',
                                                height: '10%',
                                                width: '100%',
                                                color: '#1565c0',
                                                borderRadius: '5px',
                                                borderWidth: '0px',
                                                borderColor: '#2196f3',
                                                border: '0px solid',
                                                backgroundColor: 'white',
                                            }
                                            }
                                        }}
                                        onChange={date => setDate(date.$y + '-' + Number(date.$M+1).toString().padStart(2, '0') + '-' + date.$D.toString().padStart(2, '0'))}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="flex spaceing">
                            <div className="flexComp">
                                <label for="dlEx" className="label2">Score</label>
                                <input type="text" name="dlEx" className="inputEdit" value={score} onChange={e => setScore(e.target.value)}></input>
                            </div>
                            <div className="flexComp ">
                                <label for="origin" className="label2 correction">Age</label>
                                <input type="text" name="origin" className="inputEdit correction" value={age} onChange={e => setAge(e.target.value)}></input>
                            </div>
                        </div>
                        
                    </DialogContent>
                    <DialogActions>
                       
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={createEntry} >
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Create</span>
                            <div class="fill-container"></div>
                        </button>
                        
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={e => setOpenNew(false)}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default Recruitment;