const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    {
        field: 'category',
        headerName: 'Category',
        headerClassName: 'table-header',
        width: 250,
        editable: false,
      },
    {
        field: 'description',
        headerName: 'Description',
        headerClassName: 'table-header',
        width: 250,
        editable: false,
      },
      {
        field: 'price',
        headerName: 'Price',
        headerClassName: 'table-header',
        width: 250,
        editable: false,
      },
      {
        field: 'vat',
        headerName: 'Vat',
        headerClassName: 'table-header',
        width: 250,
        editable: false,
      }
]
export default columns;