
const columns = [
    { field: 'id', headerName: 'Line', headerClassName: 'table-header', width: 50 },
    {
      field: 'document_no',
      headerName: 'Document No',
      headerClassName: 'table-header',
      width: 100,
      editable: true,
    },
    {
      field: 'category',
      headerName: 'Category',
      headerClassName: 'table-header',
      width: 100,
      editable: true,
    },
    {
      field: 'vatPull',
      headerName: 'vatPull',
      headerClassName: 'table-header',
      width: 100,
      editable: true,
    },
    {
        field: 'description',
        headerName: 'Description',
        headerClassName: 'table-header',
        width: 250,
        editable: true,
        type: 'singleSelect',
        valueOptions: []
      },
    {
        field: 'rate',
        headerName: 'Rate',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
      },
      {
        field: 'qty',
        headerName: 'Qty',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
        
      },
      {
        field: 'subtotal',
        headerName: 'Sub Total',
        headerClassName: 'table-header',
        width: 130,
        editable: false,
      },
      {
        field: 'vat',
        headerName: 'VAT',
        headerClassName: 'table-header',
        width: 130,
        editable: false,
      },
      {
        field: 'total',
        headerName: 'Total',
        headerClassName: 'table-header',
        width: 130,
        editable: false,
      }
]
export default columns;