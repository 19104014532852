const recColumns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150, editable: false },
    {
        field: 'name',
        headerName: 'Name',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'phone',
        headerName: 'Phone',
        headerClassName: 'table-header',
        width: 130,
        editable: false,
    },
    {
        field: 'dob',
        headerName: 'Date of Birth',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
    },
    {
        field: 'date',
        headerName: 'Date',
        headerClassName: 'table-header',
        width: 130,
        editable: true,
    },
    {
        field: 'time',
        headerName: 'Time',
        headerClassName: 'table-header',
        width: 70,
        editable: true,
    },
]

export default recColumns;