import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import columns from "./fleet";
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Button from '@mui/material/Button';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Papa from 'papaparse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const FleetRatecard = () => {
    const [ col, setCol ] = useState([]);
    const [ openCSV, setOpenCsv ] = useState(false);
    const [ csvData, setCsvData ] = useState([]);
    const [ data, setData ] = useState([]);
    const [ displayData, setDisplayData ] = useState([]);
    const [ category, setCategory ] = useState('');
    const [ description, setDescription ] = useState('');   
    const [ price, setPrice ] = useState('');
    const [ vat, setVat ] = useState('');
    const [ openEdit, setOpenEdit ] = useState(false);
    const [ entry, setEntry ] = useState('');
    const [ openNew, setOpenNew ] = useState(false);
    const [ openDel, setOpenDel ] = useState(false);
    const [ deleteId, setDeleteId ] = useState('');

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <Button className="incGridButton" onClick={e => setOpenCsv(true)}> 
                  <FileUploadOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  IMPORT
                </Button>
                <GridToolbarQuickFilter />
                <Button className="incGridButton" onClick={e => setOpenNew(true)}> 
                    <LibraryAddOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  NEW ENTRY
                </Button>
            </GridToolbarContainer>
        );
    }

    useEffect(() =>{
        let plc = columns
        plc.push({
            field: 'edit',
            headerName: '',
            headerClassName: 'table-header',
            width: 50,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <EditIcon onClick={e => handleParams(param.row)} />
                    </>
            )
        },
        {
            field: 'delete',
            headerName: '',
            headerClassName: 'table-header',
            width: 50,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <DeleteOutlineIcon onClick={e => deleteRow(param.row)} />
                    </>
            )
        })
        setCol(plc)

        UserService.getFleetRates().then(
            response =>{
                setData(response.data)
            }
        ).catch(err => console.log(err))    
    },[])

    useEffect(() =>{    
        let plc = []
        data.map(row =>{
            plc.push({id: row.entry, category: row.category, description: row.description, price: row.price, vat: row.vat})
        })
        setDisplayData(plc)
    },[data])

    const handleParams = (params) =>{
        console.log(params)
        setEntry(params.id)
        setCategory(params.category)
        setDescription(params.description)
        setPrice(params.price)
        setVat(params.vat)
        setOpenEdit(true)
    }

    const changeHandler = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
            const dataPlc = results.data
            let dataSave = []
            dataPlc?.map(row =>{
                dataSave.push({category: row.Category, description: row.Description, price: row.Price, vat: row.VAT})
            })
            setCsvData(dataSave)
            },
        });
    };
    
    const upload = () =>{
        UserService.createBulkFleetRates(csvData).then(
            response =>{
                console.log(response)
                setOpenCsv(false)
                window.location.reload(false)
            }
        ).catch(err => console.log(err)) 
    }

    const update = () =>{
        const dataSend = {entry: entry, category: category, description: description, price: price, vat: vat}
        UserService.updateFleetRatecard(dataSend).then(
            response =>{
                let plc = displayData
                plc.map(row =>{
                    if(row.id === entry){
                        row.category = category
                        row.description = description
                        row.price = price
                        row.vat = vat
                    }
                })
                setDisplayData(plc)
                setOpenEdit(false)
                setEntry('')
                setCategory('')
                setDescription('')
                setPrice('')
                setVat('')
            }
        ).catch(err => console.log(err))
    }

    const create = () =>{
        const dataSend = {category: category, description: description, price: price, vat: vat}
        UserService.createFleetRatecard(dataSend).then(
            response =>{
                setDisplayData([...displayData, {id: displayData.length, category: category, description: description, price: price, vat: vat}])
                setOpenNew(false)
            }
        ).catch(err => console.log(err))
    }

    const handleCloseNew = () =>{
        setOpenNew(false)
        setCategory('')
        setDescription('')
        setPrice('')
        setVat('')
        setEntry('')
    }

    const handleCloseEdit = () =>{  
        setOpenEdit(false)
        setCategory('')
        setDescription('')
        setPrice('')
        setVat('')
        setEntry('')
    }

    const deleteRow = (row) =>{
        setOpenDel(true)
        console.log(row)
        setDeleteId(row.id)
    }

    const deleteRowAccept = () =>{
        UserService.deleteFleetRatecard({entry: deleteId}).then(
            response =>{
                let plc = displayData
                plc = plc.filter(row =>{
                    return row.id !== deleteId
                })
                setDisplayData(plc)
                setOpenDel(false)
            }
        ).catch(err => console.log(err.message))
    }

    return(
        <div className='dashArch'>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Fleet Ratecard</h3>
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGridPro
                    pagination
                    rows={displayData}
                    columns={col}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '',  [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    //loading={loading}
                    //{...data}
                    //processRowUpdate={params =>handleCellEdit(params)}
                    onRowClick={params => console.log(params)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    columnVisibilityModel={{
                        id: false
                    }}
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                />
            </Box> 
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openCSV}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Upload CSV file</label></DialogTitle>
                    <DialogContent>
                        <input
                            type="file"
                            name="file"
                            accept=".csv"
                            onChange={changeHandler}
                            style={{ display: "block", margin: "10px auto" }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={upload}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Upload</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenCsv(false)}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openEdit}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Edit: {category}</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Category</label>
                                    <input type="text" name="dlNo" className="lone" value={category} onChange={e => setCategory(e.target.value)}></input>
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Price</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={price} onChange={e => setPrice(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Vat</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={vat} onChange={e => setVat(e.target.value)}></input>
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Description</label>
                                    <input type="text" name="dlNo" className="lone" value={description} onChange={e => setDescription(e.target.value)}></input>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={update}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Update</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseEdit}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openNew}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Add new:</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Category</label>
                                    <input type="text" name="dlNo" className="lone" value={category} onChange={e => setCategory(e.target.value)}></input>
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Price</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={price} onChange={e => setPrice(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Vat</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={vat} onChange={e => setVat(e.target.value)}></input>
                                </div>
                            </div>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Description</label>
                                    <input type="text" name="dlNo" className="lone" value={description} onChange={e => setDescription(e.target.value)}></input>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={create}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Create</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseNew}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openDel}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Are you sure you want to delete the line?</label></DialogTitle>
                    <DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={deleteRowAccept}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Yes</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenDel(false)}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>No</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default FleetRatecard;