const sandColumns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    
    /*{
        field: 'schedule_shortcode',
        headerName: 'Schedule Short',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },*/
    {
        field: 'description',
        headerName: 'Description',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'rate_subtotal_in',
        headerName: 'AMZL Rate',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'vehicle_type',
        headerName: 'Vehicle Type',
        headerClassName: 'table-header',
        width: 200,
        type: 'singleSelect',
        valueOptions: [],
        editable: true,
    },
    {
        field: 'duration',
        headerName: 'Duration',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'rate_subtotal_out',
        headerName: 'Day Rate',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'hourly_rate',
        headerName: 'DA Hourly Rate',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'dps_profit',
        headerName: 'DSP Profit',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'profit_percentage',
        headerName: 'Profit Percentage',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    }
]
export default sandColumns;