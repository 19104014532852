import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import vehiColumns from "./vehi";
import { alpha, useTheme } from '@mui/material/styles';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const Vehicles = (props) =>{
    const [ assets, setAssets ] = useState([])
    const [ assetsDisplay, setAssetsDisplay ] = useState([])

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{fontSize: '16px', marginTop: 0}}>
                <div className="inlines">
                    <h6 style={{marginLeft: '-280px'}}>Driver Video's Required</h6>
                    <div>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }

    useEffect(()=>{
        setAssets(props.assets)
    }, [props.assets])

    useEffect(()=>{
        let dataPlc = []
        assets?.map(ass =>{
            dataPlc.push({id: ass.entry, name: ass.assigned_to, reg: ass.reg, date: ass.video_date})
        })
        setAssetsDisplay(dataPlc)
    },[assets])

    return(
        <div className="dashArch">
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%'}}>
                <StripedDataGridPro
                    pagination
                    rows={assetsDisplay}
                    columns={vehiColumns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 50,
                            },
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[50, 100, 200]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    slotProps={{ toolbar: { csvOptions: { fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] } } }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                            return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                />
            </Box>
        </div>
    )
}
export default Vehicles;