import React from "react";
import { useEffect, useState } from "react";
import UserService from "../../services/user.service";
import sandColumns from "./sandColumns";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled } from '@mui/material/styles';


const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const RateSand = () =>{
    const [ rental, setRental ] = useState([])
    const [ displayColumns, setDisplayColumns ] = useState([])
    const [ data, setData ] = useState([])
    const [ displayData, setDisplayData ] = useState([])
    const [ field, setField ] = useState()
    const [ partials, setPartials ] = useState([])

    function CustomToolbar() {
            return (
                <GridToolbarContainer>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport csvOptions={{escapeFormulas: false}}/>
                    <GridToolbarQuickFilter />
                </GridToolbarContainer>
            );
        }

    useEffect(()=>{
        UserService.getSands().then(
            response =>{
                setPartials(response.data)
            }
        ).catch(err =>{
            console.log(err)
        })
        UserService.getRateCard().then(
            response =>{
                const dat = response.data
                console.log(dat)
                let rentalPlc = []
                dat.map(d =>{
                    if(d.schedule_shortcode?.includes('Rental')){
                        console.log(d)
                        rentalPlc.push(d.schedule_shortcode)
                    }
                })
                setRental(rentalPlc)
                setData(dat)
            }
        ).catch(err => console.log(err))
    },[])

    useEffect(()=>{
        if(rental.length > 0){
            let columns = sandColumns
            columns.forEach(col =>{
                if(col.type == 'singleSelect'){
                    col.valueOptions = rental
                }
            })
            setDisplayColumns(columns)
        }
    },[rental])

    useEffect(()=>{
        if(data.length > 0){
            let dataPlc = []
            data.map(dat =>{
                if(dat.schedule_shortcode?.includes('hr')){
                    dataPlc.push({
                        id: dat.entry, 
                        description: dat.description,
                        rate_subtotal_in: dat.rate_subtotal_in ? '£'+dat.rate_subtotal_in : '£0', 
                        duration: dat.duration, 
                        rate_subtotal_out: '£'+dat.rate_subtotal_out, 
                        hourly_rate: '',
                        dps_profit: Number(dat.rate_subtotal_in) > 0 && Number(dat.rate_subtotal_out) > 0 ? '£'+Number(dat.rate_subtotal_in-dat.rate_subtotal_out).toFixed(2) : '',
                        profit_percentage:   Number(dat.rate_subtotal_in) > 0 && Number(dat.rate_subtotal_out) > 0 ? (Number(dat.rate_subtotal_in-dat.rate_subtotal_out)*100/Number(dat.rate_subtotal_in)).toFixed(2)+'%' : ''
                    })
                }
            })

            if(partials.length > 0 ){
                console.log(partials)
                dataPlc.map(d =>{
                    partials?.map(p =>{
                        if(d.id == p.entry){
                            d.vehicle_type = p.vehicle_type
                        }
                    })
                })
            }

            dataPlc.map(d =>{
                if(d.vehicle_type){
                    data.map(dat =>{
                        if(d.vehicle_type == dat.schedule_shortcode){
                            const datOut = Number(dat.rate_subtotal_out)
                            const dOut = Number(d.rate_subtotal_out.substring(1, d.rate_subtotal_out.length))
                            const dDuration = Number(d.duration)
                            
                            d.hourly_rate = ((((dOut-1.8)*6)-datOut)/(dDuration*6)).toFixed(2)
                        }
                    })
                }
            })
           
            setDisplayData(dataPlc)
        }
    },[data, partials])

    const handleCellEdit = (params) =>{
        let dataPlc = displayData
        console.log(params)
        dataPlc.map(d =>{
            if(d.id == params.id){
                data.map(dat =>{
                    if(dat.schedule_shortcode == params[field]){
                        console.log()
                        console.log()
                        const datOut = Number(dat.rate_subtotal_out)
                        const dOut = Number(d.rate_subtotal_out.substring(1, d.rate_subtotal_out.length))
                        const dDuration = Number(d.duration)
                        
                        d.hourly_rate = ((((dOut-1.8)*6)-datOut)/(dDuration*6)).toFixed(2)
                        params.hourly_rate = ((((dOut-1.8)*6)-datOut)/(dDuration*6)).toFixed(2)
                    }
                   
                })
            }
            if(!params[field]){
                console.log('this')
                //d.hourly_rate = ''
                params.hourly_rate = ''
            }
        })
        setDisplayData(dataPlc)
        const dataSend = {
            entry: params.id, 
            vehicle_type: params.vehicle_type,
        }

        UserService.sandUpdate(dataSend).then(
            response =>{
                console.log(response)
            }
        ).catch(err => console.log(err))
        return params
    }

    return(
        <div className="dashArch">
             <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Rate Sandbox</h3>
                <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                    <StripedDataGridPro
                        //checkboxSelection
                        pagination
                        rows={displayData}
                        columns={displayColumns}
                        initialState={{
                            pagination: {
                            paginationModel: {
                                pageSize: 100,
                            },
                            },
                        }}
                        sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                            backgroundColor: '#D35858',
                            color: '#1a3e72',
                        },
                        [`.${gridClasses.cell}.hot`]: {
                            backgroundColor: '#A6D358',
                            color: '#1a3e72',
                        },}}
                        pageSizeOptions={[100]}
                        slots={{
                            loadingOverlay: LinearProgress,
                            toolbar: CustomToolbar
                        }}
                        slotProps={{toolbar: { csvOptions : {escapeFormulas: true}}}}
                        //loading={loading}
                        //{...data}
                        processRowUpdate={params =>handleCellEdit(params)}
                        onRowClick={params => console.log(params)}
                        onCellEditStop={params => setField(params.field)}
                        //onRowSelectionModelChange={params => setSelectedIds(params)}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                        columnVisibilityModel={{
                            id: false
                        }}
                        getCellClassName={(params) => {
                            if (params.field === 'city' || params.value == null) {
                            return '';
                            }
                            return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                        }}
                    />
                </Box>
        </div>
    )
}
export default RateSand;