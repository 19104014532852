import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Papa from 'papaparse';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import columns from "./scoreCard";
import Select from 'react-select';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import AuthService from "../../services/auth.service";

const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%', minHeight: '20px', fontSize: 'x-large', marginRight:'10%', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)'}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '101%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const metricOptions = [
    {value: 'Fantastic Plus', label: 'F Plus'},
    {value: 'Fantastic', label: 'Fantastic'},
    {value: 'Great', label: 'Great'},
    {value: 'Fair', label: 'Fair'},
    {value: 'Poor', label: 'Poor'},
]

const rankOptions = [
    {value: 'Rank 1', label: 'Rank 1'},
    {value: 'Top 33%', label: 'Top 33%'},
    {value: '34% - 50%', label: '34% to 50%'},
    {value: '51% - 66%', label: '51% to 66%'},
    {value: '67% - 100%', label: '67% to 100%'}
]

const ScorecardData = () =>{
    const [ csvData, setCsvData ] = useState([])
    const [ openCSV, setOpenCSV ] = useState(false)
    const [ displayData, setDisplayData ] = useState([])
    const [ showEdit, setShowEdit ] = useState(false)
    const [ amzlId, setAmzlId ] = useState()
    const [ nameHold, setNameHold ] = useState()
    const [ openAddName, setOpenAddName ] = useState(false)
    const [ nameOption, setNameOption ] = useState([])
    const [ stations, setStations ] = useState([])
    const [ station, setStation ] = useState()
    const [ overallScore, setOverallScore ] = useState()
    const [ mentor, setMentor ] = useState()
    const [ whc, setWhc ] = useState()
    const [ escalationDpmo, setEscalationDpmo ] = useState()
    const [ deliveryFeedback, setDeliveryFeedback ] = useState()
    const [ dcr, setDcr ] = useState()
    const [ dpmo, setDpmo ] = useState()
    const [ pod, setPod ] = useState()
    const [ contactCompliance, setContactCompliance ] = useState()
    const [ siteRank, setSiteRank ] = useState()
    const [ osms, setOsms ] = useState([])
    const [ checkedOsms, setCheckedOsms ] = useState([])
    const [ checkedNo, setCheckedNo ] = useState({})
    const [ week, setWeek ] = useState(new Date().getFullYear()+'-')
    const username = AuthService.getCurrentUser().username
    const [ checked, setChecked ] = useState(false)

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{fontSize: '16px', marginTop: 0}}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport /*csvOptions={{fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] , escapeFormulas: false}}*/ />
                
                <GridToolbarQuickFilter />
                <Button className="incGridButton" onClick={e => setOpenCSV(true)}> 
                  <FileUploadOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  UPLOAD DATA
                </Button>
                {/*<Button className="incGridButton" onClick={e => setOpenAddName(true)}> 
                  <FileUploadOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  IMPORT
                </Button>*/}
            </GridToolbarContainer>
        );
    }

    useEffect(()=>{

        UserService.getNamesIds().then(
            names =>{
                const data = names.data
                let dataPlc = []
                data?.map(row =>{
                    dataPlc.push({value: row.name, label: row.name})
                })
                setNameOption(dataPlc)
            }
        ).catch(err => console.log(err))

        UserService.getScoreCardData().then(
            response =>{
                const data = response.data
                let dataPlc = []
                data?.map(row =>{
                    
                    dataPlc.push({id: row.entry, week: row.week,  station: row.station,associate: row.name, amzl_id: row.amzl_id, delivered: row.delivered, dcr: row.dcr+'%', dnr_dpmo: row.dnr_dpmo, pod: row.pod+'%', cc: row.cc+'%', ce: row.ce, dex: row.dex+'%', concessions: row.concessions})
                })
                setDisplayData(dataPlc)
            }
        ).catch(err => console.log(err))

        UserService.getStations().then(
            response =>{
                const data = response.data
                let dataPlc = []
                data?.map(row =>{
                    if(!row.station_code.match('SD')){
                        dataPlc.push({value: row.station_code, label: row.station_code})
                    }
                })
                setStations(dataPlc)
            }
        )
         
    },[])

    useEffect(()=>{
        const dataSend = {station: station}
        //setCheckedOsms([])
        const noKeys = Object.keys(checkedNo)
        noKeys.map(key =>{
            delete checkedNo[key]
        })
        //setCheckedNo({})
        setOsms([])
        UserService.getOsms(dataSend).then(
            response =>{
                
                const data = response.data
                console.log(data)
                let dataPlc = []
                let dataOsmsPlc = []
                let checkedNoPlc = {}
                data?.map(row =>{
                    dataPlc.push(
                        <div className="flexComp">
                            <label for="dlEx" className="label2">
                                <Checkbox  onClick={params => {
                                    dataOsmsPlc = checkedOsms
                                    checkedNoPlc = checkedNo
                                    
                                    if(params.target.checked && !dataPlc.includes(row.name)){
                                        //dataOsmsPlc.push(row.name)
                                        console.log(row.account_no)
                                        checkedNoPlc[row.name] = row.account_no
                                    }else{
                                        dataOsmsPlc = dataOsmsPlc.filter(item => item !== row.name)
                                        delete checkedNoPlc[row.name]
                                    }
                                    
                                    console.log(checkedNoPlc)
                                    setCheckedOsms(dataOsmsPlc)
                                    setCheckedNo(checkedNoPlc)
                                }}/> {row.name}
                            </label>
                        </div>
                    )
                })
                setOsms(dataPlc)
            }   
        ).catch(err => console.log(err))
    },[station])
    
    const changeHandler = (event) => {
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        UserService.getNamesIds().then(
            names =>{
                const namesIds = names.data
                Papa.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    const dataPlc = results.data
                    let dataSave = []
                    dataPlc?.map(row =>{
                        let name = ""
                        namesIds?.map(idRow =>{
                            if(row['Transporter ID'] == idRow.amzl_id){
                                name = idRow.name
                            }
                        })
                        dataSave.push({week: row.Week, station: station, name: name, amzl_id: row['Transporter ID'], delivered: row['Packages Delivered'], dcr: (row.DCR*100).toFixed(2), dnr_dpmo: row['DNR DPMO'], pod: (row['SWC-POD']*100).toFixed(2), cc: (row['SWC-CC']*100).toFixed(2), ce: row.CE, dex: (row.CDF*100).toFixed(2), concessions: row.DNR})
                    })
                    setCsvData(dataSave)
                },
                });
            }
        ).catch(err => console.log(err))
    }

    const handleAddName = (params) =>{
        if(!params.associate && params.amzl_id){
            setShowEdit(true)
            setAmzlId(params.amzl_id)
        }else{
            setShowEdit(false)
        }
    }

    const updateNameId = () =>{
        if(nameHold && amzlId){
            const data = {name: nameHold, amzl_id: amzlId}
            UserService.updateNameId(data).then(
                response =>{
                    let dataPlc = []
                    displayData?.map(row =>{
                        if(row.amzl_id == amzlId){
                            row.associate = nameHold
                        }
                        dataPlc.push(row)
                    })
                    setDisplayData(dataPlc)
                    setOpenAddName(false)
                    setShowEdit(false)
                }
            ).catch(err => console.log(err))
        }
    }
   
    const handleWeek = (value) =>{
        if(week.length < 7 && Number(value.split('-')[1]) < 53){
            setWeek(value)
        }else if(value.length < 7){
            setWeek(value)
        }
    }

    const handleCloseUpload = () =>{
        setOpenCSV(false)
        setWeek(new Date().getFullYear()+'-')
        setStation()
        setOverallScore()
        setMentor()
        setWhc()
        setEscalationDpmo()
        setDeliveryFeedback()
        setDcr()
        setDpmo()
        setPod()
        setContactCompliance()
        setSiteRank()
        setOsms([])
        setCheckedOsms([])
        setCheckedNo({})
        setCsvData([])
    }

    const upload = () =>{
        
        UserService.sendScoreCardData(csvData).then(
            response =>{
                const dataSend = {
                    station: station, 
                    overallScore: 'Overall Scorecard - '+overallScore,
                    mentor: 'Mentor - '+mentor, 
                    whc: 'WHC - '+whc, 
                    escalationDpmo: 'Escalation DPMO - '+escalationDpmo, 
                    deliveryFeedback: 'Delivery Feedback - '+deliveryFeedback, 
                    dcr: 'DCR - '+dcr, 
                    dpmo: 'DPMO - '+dpmo, 
                    pod: 'POD - '+pod, 
                    contactCompliance: 'Contact Compliance - '+contactCompliance, 
                    siteRank: siteRank == 'Rank 1' || siteRank == 'Top 33%' ? 'Site Rank - '+ siteRank : 'Site Rank '+siteRank, 
                    week: week,
                    osms: checkedOsms,
                    osmsNo: checkedNo,
                    username: username
                }
                UserService.uploadOsmsBonus(dataSend).then(
                    response =>{
                        window.location.reload(false)
                    }
                ).catch(err => console.log(err))
            }
        ).catch(err => console.log(err))
    }

    return(
        <div className="dashArch">
            <h3 className="h3TitlesCorrection" style={{marginBottom: '50px'}}>Score Card Data</h3>
            {/*<button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenCSV(true)}>
                <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Upload Data</span>
                <div class="fill-container"></div>
            </button>
            {showEdit ? <button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenAddName(true)}>
                <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Add Name</span>
                <div class="fill-container"></div>
            </button> : ''}*/}
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop: 5}}>
                <StripedDataGridPro
                    
                    pagination
                    rows={displayData}
                    
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 50,
                        },
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[50, 100, 200]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    slotProps={{ toolbar: { csvOptions: { fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] } } }}
                    //loading={loading}
                    //processRowUpdate={params =>handleCellEdit(params)}
                    //onRowSelectionModelChange={params => setSelectedIds(params)}
                    onRowClick={params => handleAddName(params.row)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    //onColumnVisibilityModelChange={param => setHidenColumns(param)}    
                    //columnVisibilityModel={hidenColumns}
                    //ignoreValueFormatterDuringExport
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                    //{...displayData}
                />
            </Box>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openCSV}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Upload scorecard data</label></DialogTitle>
                    <DialogContent>
                        <div className="flex spaceing" style={{width: '99.5%'}}>   
                            <div className="flexComp" >
                                <label for="dlEx" className="label2">Station</label>
                                <Select 
                                    options={stations}
                                    styles={colourStyles}
                                    //value={{label: nameHold, value: nameHold}}
                                    onChange={e => setStation(e.value)}
                                /> 
                            </div>
                            
                        </div>
                        
                        <div className="flex spaceing">  
                            <div className="flexComp">
                                <label for="dlEx" className="label2">Overall Score</label>
                                <Select 
                                    options={metricOptions}
                                    styles={colourStyles}
                                    //value={{label: nameHold, value: nameHold}}
                                    onChange={e => setOverallScore(e.value)}
                                /> 
                            </div> 
                            <div className="flexComp">
                                <label for="dlEx" className="label2">Mentor</label>
                                <Select 
                                    options={metricOptions}
                                    styles={colourStyles}
                                    //value={{label: nameHold, value: nameHold}}
                                    onChange={e => setMentor(e.value)}
                                /> 
                            </div>
                        </div>
                        <div className="flex spaceing" style={{width: '99.5%'}}>
                            <div className="flexComp">
                                    <label for="dlEx" className="label2">WHC</label>
                                    <Select 
                                        options={metricOptions}
                                        styles={colourStyles}
                                        //value={{label: nameHold, value: nameHold}}
                                        onChange={e => setWhc(e.value)}
                                    /> 
                            </div>
                        </div>
                        <div className="flex spaceing">   
                            <div className="flexComp">
                                <label for="dlEx" className="label2">Escalation DPMO</label>
                                <Select 
                                    options={metricOptions}
                                    styles={colourStyles}
                                    //value={{label: nameHold, value: nameHold}}
                                    onChange={e => setEscalationDpmo(e.value)}
                                /> 
                            </div>
                            <div className="flexComp">
                                <label for="dlEx" className="label2">Delivery Feedback</label>
                                <Select 
                                    options={metricOptions}
                                    styles={colourStyles}
                                    //value={{label: nameHold, value: nameHold}}
                                    onChange={e => setDeliveryFeedback(e.value)}
                                /> 
                            </div>
                        </div>
                        <div className="flex spaceing">   
                            <div className="flexComp">
                                <label for="dlEx" className="label2">DCR</label>
                                <Select 
                                    options={metricOptions}
                                    styles={colourStyles}
                                    //value={{label: nameHold, value: nameHold}}
                                    onChange={e => setDcr(e.value)}
                                /> 
                            </div>
                            <div className="flexComp">
                                <label for="dlEx" className="label2">DPMO</label>
                                <Select 
                                    options={metricOptions}
                                    styles={colourStyles}
                                    //value={{label: nameHold, value: nameHold}}
                                    onChange={e => setDpmo(e.value)}
                                /> 
                            </div>
                        </div>
                        <div className="flex spaceing">   
                            <div className="flexComp">
                                <label for="dlEx" className="label2">POD</label>
                                <Select 
                                    options={metricOptions}
                                    styles={colourStyles}
                                    //value={{label: nameHold, value: nameHold}}
                                    onChange={e => setPod(e.value)}
                                /> 
                            </div>
                            <div className="flexComp">
                                <label for="dlEx" className="label2">Contact Compliance</label>
                                <Select 
                                    options={metricOptions}
                                    styles={colourStyles}
                                    //value={{label: nameHold, value: nameHold}}
                                    onChange={e => setContactCompliance(e.value)}
                                /> 
                            </div>
                        </div>
                        <div className="flex spaceing" style={{width: '99.5%'}}>   
                            <div className="flexComp" >
                                <label for="dlEx" className="label2">Site Rank</label>
                                <Select 
                                    options={rankOptions}
                                    styles={colourStyles}
                                    //value={{label: nameHold, value: nameHold}}
                                    onChange={e => setSiteRank(e.value)}
                                /> 
                            </div>
                            <div className="flexComp" >
                                <label for="dlEx" className="label2">Week No</label>
                                <input type="text" name="dlEx" className="inputEdit" value={week} onChange={e => handleWeek(e.target.value)}></input> {/*inplementing--------------------------------------------------------- */}
                            </div>
                        </div>
                        <div className="flex spaceing" style={{width: '99.5%'}}>  
                            <div className="flexComp" >
                                <label for="dlEx" className="label2">Osms qualified for bonus</label>
                            </div>
                        </div>
                        
                        <div className="flex spaceing" style={{width: '99.5%'}}>   
                            {osms} 
                        </div>
                        <div className="flex spaceing" style={{width: '99.5%'}}>   
                            <div className="flexComp" >
                                <label for="dlEx" className="label2">Upload CSV</label>
                                <input
                                    type="file"
                                    name="file"
                                    accept=".csv"
                                    onChange={changeHandler}
                                    style={{ display: "block", margin: "10px " , marginTop: '50px'}}
                                />
                            </div>
                        </div>
                       
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={upload}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Upload</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseUpload}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openAddName}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Add name for the Transporter id</label></DialogTitle>
                    <DialogContent>
                        <h4 className="label">{amzlId}</h4>
                        <div className="flexComp">
                            <label for="dlEx" className="label2">Name</label>
                            <Select 
                                options={nameOption}
                                styles={colourStyles}
                                //value={{label: nameHold, value: nameHold}}
                                onChange={e => setNameHold(e.value)}
                            /> 
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={updateNameId}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Add</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenAddName(false)}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default ScorecardData;