const vehiColumns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150, editable: false },
    {
        field: 'name',
        headerName: 'Name',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'reg',
        headerName: 'Reg',
        headerClassName: 'table-header',
        width: 130,
        editable: false,
    },
    {
        field: 'date',
        headerName: 'Date',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
    }
]

export default vehiColumns;