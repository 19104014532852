import React from "react";
import { useState, useEffect } from "react";
import columns from "./rate";
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import UserService from "../../services/user.service";
import isEmail from "validator/lib/isEmail";
import Button from '@mui/material/Button';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AuthService from "../../services/auth.service";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const ManageRates = () => {
    const [ displayData, setDisplayData ] = useState([])
    const [ hide, setHide ] = useState({id: false})
    const [ area, setArea ] = useState()
    const [ station_code, setStation_code ] = useState()
    const [ role, setRole ] = useState()
    const [ rate1, setRate1 ] = useState()
    const [ rate2, setRate2 ] = useState()
    const [ vanrate1, setVanrate1 ] = useState()
    const [ vanrate2, setVanrate2 ] = useState()
    const [ incentive, setIncentive ] = useState()
    const [ incentive_notes, setIncentive_notes ] = useState()
    const [ address1, setAddress1 ] = useState()
    const [ address2, setAddress2 ] = useState()
    const [ town, setTown ] = useState()
    const [ county, setCounty ] = useState()
    const [ postcode, setPostcode ] = useState()
    const [ map, setMap ] = useState()
    const [ contact, setContact ] = useState()
    const [ phone, setPhone ] = useState()
    const [ email, setEmail ] = useState()
    const [ status, setStatus ] = useState()
    const [ notes, setNotes ] = useState()
    const [ updatedBy, setUpdatedBy ] = useState(AuthService.getCurrentUser().username)
    const [ openNew, setOpenNew ] = useState(false)
    const [ openDel, setOpenDel ] = useState(false)
    const [ selectedIds, setSelectedIds ] = useState([])
    const [ field, setField ] = useState()

    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport csvOptions={{escapeFormulas: false}}/>
            <Button className="incGridButton" onClick={e => setOpenNew(true)}> 
                <LibraryAddOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  NEW ENTRY
            </Button>
            {selectedIds.length > 0 ? 
                    
                <Button className="incGridButton" onClick={e => setOpenDel(true)}> 
                    <DeleteOutlineIcon fontSize="small" style={{marginRight: '7px'}}/>  DELETE SELECTED
                </Button>
            :
                <Button className="incGridButton" style={{color: 'gray'}}> 
                    <DeleteOutlineIcon fontSize="small" style={{marginRight: '7px'}}/>  DELETE SELECTED
                </Button>
                    
            }
          </GridToolbarContainer>
        );
    }

    useEffect(() => {
        UserService.getStations().then(
            response =>{
                console.log(response.data)
                const data = response.data
                let plc = []
                data?.map(row =>{
                    plc.push({
                        id: row.primary,
                        area: row.area,
                        station_code: row.station_code,
                        role: row.role,
                        rate1: row.rate1,
                        rate2: row.rate2,
                        vanrate1: row.vanrate1,
                        vanrate2: row.vanrate2,
                        incentive: row.incentive,
                        incentive_notes: row.incentive_notes,
                        address1: row.address1,
                        address2: row.address2,
                        town: row.town,
                        county: row.county,
                        postcode: row.postcode,
                        map: row.map,
                        contact: row.contact,
                        phone: row.phone,
                        email: row.email,
                        status: row.status,
                        notes: row.notes,
                        updatedBy: row.updatedBy,
                    })
                })
                setDisplayData(plc)
            } 
        ).catch(err =>{
            console.log(err)
        })
    }, []);

    const handleCloseNew = () => {
        setOpenNew(false)
    }

    const addEntry = () => {
        let dataSend = {
            area: area,
            station_code: station_code,
            role: role,
            rate1: rate1,
            rate2: rate2,
            vanrate1: vanrate1,
            vanrate2: vanrate2,
            incentive: incentive,
            incentive_notes: incentive_notes,
            address1: address1,
            address2: address2,
            town: town,
            county: county,
            postcode: postcode,
            map: map,
            contact: contact,
            phone: phone,
            email: email,
            status: status,
            notes: notes,
            updatedBy: updatedBy
        }
        UserService.insertStation(dataSend).then(
            response =>{
                UserService.findStation(dataSend).then(
                    response =>{
                        const data = response.data
                        const plc = {
                            id: data.primary,
                            area: data.area,
                            station_code: data.station_code,
                            role: data.role,
                            rate1: data.rate1,
                            rate2: data.rate2,
                            vanrate1: data.vanrate1,
                            vanrate2: data.vanrate2,
                            incentive: data.incentive,
                            incentive_notes: data.incentive_notes,
                            address1: data.address1,
                            address2: data.address2,
                            town: data.town,
                            county: data.county,
                            postcode: data.postcode,
                            map: data.map,
                            contact: data.contact,
                            phone: data.phone,
                            email: data.email,
                            status: data.status,
                            notes: data.notes,
                            updatedBy: data.updatedBy,
                        }
                        setDisplayData([...displayData, plc])
                        setOpenNew(false)
                    }
                ).catch(err =>{
                    console.log(err)
                })
            }
        ).catch(err =>{
            console.log(err)
        })
    }

    const handleDelClose = () => {
        setOpenDel(false)
    }

    const deleteSelected = () => {
        UserService.deleteStations(selectedIds).then(
            response => {
                const newData = displayData.filter((row) => !selectedIds.includes(row.id));
                setDisplayData(newData)
                setOpenDel(false)
            }
        ).catch(err =>{
            console.log(err)
        })
    }

    const updateRow = (params) =>{
        console.log(params)
        console.log(field)
        const dataSend = {primary: params.id, [field]: params[field], field: field, updatedBy: AuthService.getCurrentUser().username}
        console.log(dataSend)
        UserService.updateStation(dataSend).then(
            response =>{
                console.log(response)
            }
        ).catch(err =>{
            console.log(err)
        })
        return params
    }

    return(
        <div className='dashArch'>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Locations and Rates</h3>
            <Box sx={{ minHeight: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGridPro
                    pagination
                    checkboxSelection
                    rows={displayData}
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '',  [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    //loading={loading}
                    {...displayData}
                    onCellEditStop={(params) => setField(params.field)}
                    processRowUpdate={(params) => updateRow(params)}
                    onProcessRowUpdateError={(params) => console.log(params)}
                    onRowSelectionModelChange={params => setSelectedIds(params)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    onColumnVisibilityModelChange={param => setHide(param)}    
                    columnVisibilityModel={hide}
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                    
                />
            </Box> 
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openNew}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Create new entry</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Area</label>
                                    <input type="text" name="dlNo" className="lone" value={area} onChange={e => setArea(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Station Code</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={station_code} onChange={e => setStation_code(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Role</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={role} onChange={e => setRole(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Rate 1</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={rate1} onChange={e => setRate1(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Rate 2</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={rate2} onChange={e => setRate2(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Van Rate 1</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={vanrate1} onChange={e => setVanrate1(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Van Rate 2</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={vanrate2} onChange={e => setVanrate2(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Incentive</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={incentive} onChange={e => setIncentive(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Incentive Notes</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={incentive_notes} onChange={e => setIncentive_notes(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Address 1</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={address1} onChange={e => setAddress1(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Address 2</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={address2} onChange={e => setAddress2(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Town</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={town} onChange={e => setTown(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">County</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={county} onChange={e => setCounty(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Post Code</label>
                                    <input type="text" name="dlNo" className="lone" value={postcode} onChange={e => setPostcode(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Map</label>
                                    <input type="text" name="dlNo" className="lone" value={map} onChange={e => setMap(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Contact</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={contact} onChange={e => setContact(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Phone</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={phone} onChange={e => setPhone(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Email</label>
                                    <input type="text" name="dlNo" className="lone" value={email} onChange={e => setEmail(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Status</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={status} onChange={e => setStatus(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Notes</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={notes} onChange={e => setNotes(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Updated By</label>
                                    <input type="text" name="dlNo" className="lone" value={updatedBy} disabled></input>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={addEntry}>                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Add Entry</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseNew} >
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openDel}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Are you sure you want to delete the selected lines?</label></DialogTitle>
                    <DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={deleteSelected}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Yes</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleDelClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>No</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}    
export default ManageRates;