const scoreColumns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    {
        field: 'week',
        headerName: 'Week',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
    },
    {
        field: 'delivered',
        headerName: 'Delivered',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
    },
    {
        field: 'dcr',
        headerName: 'DCR',
        headerClassName: 'table-header',
        width: 215,
        editable: false,
    },
    {
        field: 'dpmo',
        headerName: 'DPMO',
        headerClassName: 'table-header',
        width: 215,
        editable: false,
    },
    {
        field: 'concessions',
        headerName: 'Concessions',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
    },
    {
        field: 'pod',
        headerName: 'POD',
        headerClassName: 'table-header',
        width: 215,
        editable: false,
    },
    {
        field: 'cc',
        headerName: 'CC',
        headerClassName: 'table-header',
        width: 215,
        editable: false,
    },
    {
        field: 'ce',
        headerName: 'CE',
        headerClassName: 'table-header',
        width: 215,
        editable: false,
    },
    {
        field: 'dex',
        headerName: 'DEX',
        headerClassName: 'table-header',
        width: 215,
        editable: false,
    }
]
export default scoreColumns;