import React, { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import dispatchColumns from "./nandsCol";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Tab from '@mui/material/Tab';
import Dispatch from "./dispatch.component";
import Paper from '@mui/material/Paper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js';
import Select from 'react-select';
import Recruitment from "./recruitment.component";
import Vehicles from "./vehicles.component";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#e8e8e8' , minWidth: '80%', minHeight: '20px', fontSize: 'large', marginRight:'10%', marginTop: '1%', marginLeft: '10%', color: 'rgb(97, 97, 97)', border: '1px solid rgb(153, 153, 153)', borderRadius: '5px'}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '80%',
        marginLeft:'10%',
        fontSize: 'large',
        marginTop: '0px',
        
    }), 
}

function getWeekNumber(dateString) {
    const [year, month, day] = dateString?.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);

    return weekNumber;
}

const OsmDash = () => {
    const [ value, setValue ] = useState('1')
    const user = AuthService.getCurrentUser();
    const [ depots, setDepots ] = useState([])
    const [ depot, setDepot ] = useState()
    const [ date, setDate ] = useState()
    const [ sd, setSd ] = useState([])
    const [ nd, setNd ] = useState([])
    const [ standard, setStandard ] = useState(0)
    const [ large, setLarge ] = useState(0)
    const [ vr, setVr ] = useState(0)
    const [ vc, setVc ] = useState(0)
    const [ day, setDay ] = useState()
    const [ year, setYear ] = useState()
    const [ week, setWeek ] = useState()
    const [ activeUsers, setActiveUsers ] = useState(0)
    const [ onboardingUsers, setOnboardingUsers ] = useState(0)
    const [ interUsers, setInterUsers ] = useState(0)
    const [ activeAssets, setActiveAssets ] = useState([])
    const [ inspectionAssets, setInspectionAssets ] = useState([])
    const [ inspectionDue, setInspectionDue ] = useState(0)
    const [ interviews, setInterviews ] = useState([])
    const [ partials, setPartials ] = useState([])

    useEffect(() => {
        UserService.getStations().then(
            response =>{
                let dataPlc = []
                const data = response.data
                data.map(dat =>{
                    if(!dat.station_code.includes('SD')){
                        dataPlc.push({label: dat.station_code, value: dat.station_code})
                    }
                })
                setDepots(dataPlc)
            }
        ).catch(err => console.log(err))

        UserService.getActiveAssets().then(
            response =>{
                setActiveAssets(response.data)
            }
        ).catch(err => console.log(err))
    },[])

    useEffect(() =>{
        if(depot, date){
            UserService.getUserDetails().then(
                response =>{
                    const data = response.data
                    let activePlc = 0
                    let onboardingPlc = 0
                    let interPlc = 0
                    let interDataPlc = []
                    data.map(dat =>{
                        if(dat.status == 'Active' && dat.station == depot){
                            activePlc += 1
                        }
                        if(dat.status == 'Onboarding' && dat.station == depot){
                            onboardingPlc += 1
                        }
                        if(dat.apply_interview_date == date && dat.status == 'Pending'){
                            if(dat.station == depot){
                                interDataPlc.push({id: dat.entry, name: dat.name, phone: dat.phone, dob: dat.dob, date: dat.apply_interview_date, time: dat.apply_interview_time})
                                interPlc += 1
                            }
                        }
                    })
                    setInterviews(interDataPlc)
                    setInterUsers(interPlc)
                    setActiveUsers(activePlc)
                    setOnboardingUsers(onboardingPlc)
                    console.log(response.data)
                }
            ).catch(err => console.log(err))
        }
    },[depot, date])

    useEffect(() => {
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    
        if(date && depot){
            setDay(days[new Date(date?.split('/')[2]+'-'+date?.split('/')[1]+'-'+date?.split('/')[0]).getDay()])
            setYear(date?.split('-')[0])
            setWeek(getWeekNumber(date))
            setSd([])
            setNd([])
            setStandard(0)
            setLarge(0)
            UserService.getPartial({date: date, station: depot}).then(
                response => {
                    const data = response.data
                    console.log(data)
                    setPartials(data)
                    /*let sdPlc = []
                    let ndPlc = []
                    let standardPlc = 0
                    let largePlc = 0
                    
                    data.map(dat =>{
                        if(dat.service_type.includes('SD')){
                            sdPlc.push({id: dat.entry, name: dat.name, type: dat.service_type, wave: dat.wave_time})
                        }else if(dat.service_type.includes('hr') && !dat.service_type.includes('SD')){
                            ndPlc.push({id: dat.entry, name: dat.name, type: dat.service_type, wave: dat.wave_time})
                        }
                        if(dat.service_type.includes('MWB')){
                            standardPlc += 1
                        }
                        if(dat.service_type.includes('LWB')){
                            largePlc += 1
                        }    
                    })

                    setSd(sdPlc)
                    setNd(ndPlc)
                    setStandard(standardPlc)
                    setLarge(largePlc)*/
                }
            ).catch(err => console.log(err))
        }
    },[date, depot])

    useEffect(()=>{
        if(depot && date && activeAssets){
            let assetsPlc = []
            activeAssets?.map(ass =>{
                let lastTime = 0
                let todayTime = new Date(date).getTime()
                if(ass.video_date){
                    lastTime = new Date(ass.video_date.split('-')[2]+'-'+ass.video_date.split('-')[1]+'-'+ass.video_date.split('-')[0]).getTime()
                }
                if(ass.assigned_location == depot && (!ass.video_date || lastTime < todayTime-14*86400000)){
                    assetsPlc.push(ass)
                }
            })
            setInspectionDue(assetsPlc.length)
            setInspectionAssets(assetsPlc)
        }   
    }, [ depot, date, activeAssets])

    useEffect(()=>{
        if(day && week && year && depot){
            const dataSend = {station: depot, year: year, week: week}
            UserService.getVrAndVc(dataSend).then(
                response =>{
                    const data = response.data
                    console.log(data)
                    let vrPlc = 0
                    let vcPlc = 0 
                    let sdPlc = []
                    let ndPlc = []
                    let standardPlc = 0
                    let largePlc = 0
                    data.map(dat =>{
                        if(dat[day] == 'VR'){
                            vrPlc += 1
                        }
                        if(dat[day] == 'VC'){
                            vcPlc += 1
                        }
                       
                        if(dat[day]?.includes('SD') && dat[day]){
                            sdPlc.push({id: dat.entry, name: dat.name, service_type: dat[day]/*, wave: dat.wave_time*/})
                        }else if(dat[day]?.includes('hr') && !dat[day]?.includes('SD')){
                            ndPlc.push({id: dat.entry, name: dat.name, service_type: dat[day]/*, wave: dat.wave_time*/})
                        }
                        if(dat[day]?.includes('MWB')){
                            standardPlc += 1
                        }
                        if(dat[day]?.includes('LWB')){
                            largePlc += 1
                        }    
                        
                    })
                    setSd(sdPlc)
                    setNd(ndPlc)
                    setStandard(standardPlc)
                    setLarge(largePlc)
                    setVr(vrPlc)
                    setVc(vcPlc)
                }
            ).catch(err => console.log(err))
        }
    },[day, week, year, depot])

    useEffect(() =>{
        if(partials?.length > 0, sd?.length > 0, nd?.length > 0){
            let ndPlc = nd
            let sdPlc = sd
            partials?.map(par =>{
                ndPlc.map(nd =>{
                    if(par.name == nd.name){
                        nd.wave_time = par.wave_time
                        nd.route_code = par.route_code
                        nd.notes = par.notes
                    }
                })
                sdPlc.map(sd =>{
                    if(par.name == sd.name){
                        sd.wave_time = par.wave_time
                        sd.route_code = par.route_code
                        sd.notes = par.notes
                    }
                })
            })
            setSd(sdPlc)
            setNd(ndPlc)
        }
    }, [sd, nd, partials])
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <div className="dashArch" style={{minWidth: '100%', maxWidth: '100%'}}>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '50px'}}>OSM Dashboard</h3>
            <div className="gridContOsm">
                <div>
                    <Paper elevation={0} style={{width: '100%', height: '200px', marginLeft: '0px', backgroundColor: 'rgb(175, 223, 233)'}}>
                        <div>
                            <div>
                                <label style={{paddingTop: '10px', marginLeft: '0px', marginTop: '0px', fontSize: '22px', color: '#6e6e6e', fontWeight: 600}}>Depot</label>
                                <Select 
                                    options={depots}
                                    styles={colourStyles}
                                    //value={{label: depot, value: depot}}
                                    onChange={e => setDepot(e.value)}
                                />
                            </div>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                        label="pick a date" 
                                        format="DD/MM/YYYY"
                                        slotProps={{
                                            textField: {
                                            sx: {
                                                marginTop: '30px',
                                                width: '185px',
                                                color: '#1565c0',
                                                borderRadius: '5px',
                                                borderWidth: '0px',
                                                borderColor: '#2196f3',
                                                border: '0px solid',
                                                backgroundColor: '#e8e8e8',
                                                zIndex:'5'
                                            }
                                            }
                                        }}
                                        onChange={date => setDate(date.$y + '-' + Number(date.$M+1).toString().padStart(2, '0') + '-' + date.$D.toString().padStart(2, '0'))}
                                    />
                                </LocalizationProvider> 
                            </div>
                        </div>    
                    </Paper>
                </div>
                <div>
                    <Paper elevation={3} style={{width: '100%', height: '200px', marginLeft: '0px', paddingRight: '0%'}}>
                        <div>
                            <label style={{paddingTop: '22%', marginTop: '0px', fontSize: '24px', color: '#6e6e6e'}}>{activeUsers}</label>
                            <label style={{marginTop: '0px', fontSize: '18px', fontWeight: 600, color: '#6e6e6e'}}>Active DAs </label>
                        </div>
                        
                    </Paper>
                </div>
                <div>
                    <Paper elevation={3} style={{width: '100%', height: '200px', marginLeft: '0px', paddingRight: '0%'}}>
                        <div>   
                            <div style={{borderBottom: '1px solid #e0e0e0'}}>
                                <label style={{paddingTop: '10px', marginTop: '0px', fontSize: '24px', color: '#6e6e6e'}}>{nd.length}</label>
                                <label style={{marginTop: '0px', fontSize: '18px', fontWeight: 600, color: '#6e6e6e'}}>ND Scheduled</label>
                            </div>
                            <div >
                                <label style={{paddingTop: '10px', marginTop: '0px', fontSize: '24px', color: '#6e6e6e'}}>{sd.length}</label>
                                <label style={{marginTop: '0px', fontSize: '18px', fontWeight: 600, color: '#6e6e6e'}}>SD Scheduled</label>  
                            </div>
                        </div> 
                    </Paper>
                </div>
                <div>
                    <Paper elevation={3} style={{minWidth: '100%', height: '200px', marginLeft: '0px', paddingRight: '0%'}}>
                        <div>   
                            <div style={{borderBottom: '1px solid #e0e0e0'}}>
                                <label style={{paddingTop: '10px', marginTop: '0px', fontSize: '24px', color: '#6e6e6e'}}>{standard}</label>
                                <label style={{marginTop: '0px', fontSize: '18px', fontWeight: 600, color: '#6e6e6e'}}>Standard Vans</label>
                            </div>
                            <div >
                                <label style={{paddingTop: '10px', marginTop: '0px', fontSize: '24px', color: '#6e6e6e'}}>{large}</label>
                                <label style={{marginTop: '0px', fontSize: '18px', fontWeight: 600, color: '#6e6e6e'}}>Large Vans</label> 
                            </div>
                        </div> 
                    </Paper>
                </div>
                <div>
                    <Paper elevation={3} style={{width: '100%', height: '200px', marginLeft: '0px', paddingRight: '0%'}}>
                        <div>   
                            <div style={{borderBottom: '1px solid #e0e0e0'}}>
                                <label style={{paddingTop: '10px', marginTop: '0px', fontSize: '24px', color: '#6e6e6e'}}>{vr}</label>
                                <label style={{marginTop: '0px', fontSize: '18px', fontWeight: 600, color: '#6e6e6e'}}>Van Returns</label>
                            </div>
                            <div >
                                <label style={{paddingTop: '10px', marginTop: '0px', fontSize: '24px'}}>{vc}</label>
                                <label style={{marginTop: '0px', fontSize: '18px', fontWeight: 600, color: '#6e6e6e'}}>Collections</label> 
                            </div>
                        </div>  
                    </Paper>
                </div>
                <div>
                    <Paper elevation={3} style={{width: '100%', height: '200px', marginLeft: '0px', paddingRight: '0%'}}>
                        <div>   
                            <div style={{borderBottom: '1px solid #e0e0e0'}}>
                                <label style={{paddingTop: '10px', marginTop: '0px', fontSize: '24px', color: '#6e6e6e'}}>{onboardingUsers}</label>
                                <label style={{marginTop: '0px', fontSize: '18px', fontWeight: 600, color: '#6e6e6e'}}>Onboarding DAs</label>
                            </div>
                            <div >
                                <label style={{paddingTop: '10px', marginTop: '0px', fontSize: '24px', color: '#6e6e6e'}}>{interUsers}</label>
                                <label style={{marginTop: '0px', fontSize: '18px', fontWeight: 600, color: '#6e6e6e'}}>Interviews</label> 
                            </div>
                        </div>
                    </Paper>
                </div>
                <div>
                    <Paper elevation={3} style={{width: '100%', height: '200px', marginLeft: '0px', paddingRight: '0%'}}>
                        <div>   
                            <label style={{paddingTop: '22%', marginTop: '0px', fontSize: '24px', color: '#6e6e6e'}}>{inspectionDue}</label>
                            <label style={{marginTop: '0px', fontSize: '18px', fontWeight: 600, color: '#6e6e6e'}}>Vehicle Inspections Due</label>
                        </div> 
                    </Paper>
                </div>
            </div>
            <Paper elevation={3} style={{width: '100%', marginLeft: '0%', marginBottom: '50px', paddingRight: '0%'}}>
                <Box sx={{ width: '95%', typography: 'body1', marginLeft: '2%', marginTop:'2%' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable"
    scrollButtons="on" /*centered*/>
                            
                            <Tab label="Dispatch" value="1" />
                            <Tab label="Recruitment" value="2" />
                            <Tab label="Vehicles" value="3" />
                        </TabList>
                        </Box>
                            <TabPanel value="1">
                                {nd.length > 0 || sd.length > 0 ? <Dispatch nexDay={nd} sameDay={sd} weekNo={week} year={year} depot={depot} date={date}/> : ''}           
                            </TabPanel>
                            <TabPanel value="2">
                                <Recruitment interviews={interviews} depots={depots} depot={depot}/>     
                            </TabPanel>
                            <TabPanel value="3">
                                <Vehicles assets={inspectionAssets} />            
                            </TabPanel>
                    </TabContext>
                </Box>
            </Paper>
            {/**/}
        </div>
    )
}
export default OsmDash;