import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import dispatchColumns from "./nandsCol";
import { alpha, useTheme } from '@mui/material/styles';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AuthService from "../../services/auth.service";

const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const Dispatch = (props) => {
    const [ nextDay, setNextDay ] = useState([])
    const [ sameDay, setSameDay ] = useState([])
    const [ field, setField ] = useState()
    const [ weekNo, setWeekNo ] = useState()
    const [ year, setYear ] = useState()
    const [ date, setDate ] = useState()
    const [ depot, setDepot ] = useState()

    function CustomToolbarA() {
        return (
            <GridToolbarContainer sx={{fontSize: '16px', marginTop: 0}}>
                <div className="inlines">
                    <h6 style={{marginLeft: '-280px'}}>NEXT DAY DISPATCH</h6>
                    <div>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }

    function CustomToolbarB() {
        return (
            <GridToolbarContainer sx={{fontSize: '16px', marginTop: 0}}>
                <div className="inlines">
                    <h6 style={{marginLeft: '-280px'}}>SAME-DAY DISPATCH</h6>
                    <div>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarExport />
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        console.log(props);
        setNextDay([]);
        setSameDay([]); 
        if (props.nexDay?.length > 0) {
            setNextDay(props.nexDay);
        }
        if (props.sameDay?.length > 0) {
            setSameDay(props.sameDay);
        }
        if(props.date){
            setDate(props.date)
        }
        if(props.weekNo){
            setWeekNo(props.weekNo)
        }
        if(props.year){
            setYear(props.year)
        }
        if(props.depot){
            setDepot(props.depot)
        }
    }, [props.nexDay, props.sameDay, props.weekNo, props.date, props.year, props.depot]);

    useEffect(() => {
        console.log(nextDay);
        console.log(sameDay);
    }, [nextDay, sameDay]);

    const handleEdit = (params) =>{
        console.log(params)
        console.log(field)
        let dataSend = {
            name: params.name,
            [field] : params[field],
            service_type: params.service_type,
            station: depot,
            year: year,
            week_no: year+'-'+weekNo,
            date: date
        }
        UserService.updatePartial(dataSend).then(
            response =>{
                console.log(response.data)
                
            }
        ).catch(err => console.log(err))
        return params
    }
    return (
        <div className="dashArch">
            <div className="gridCont">
                <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%'}}>
                    <StripedDataGridPro
                        pagination
                        rows={nextDay}
                        columns={dispatchColumns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                            backgroundColor: '#D35858',
                            color: '#1a3e72',
                        },
                        [`.${gridClasses.cell}.hot`]: {
                            backgroundColor: '#A6D358',
                            color: '#1a3e72',
                        },}}
                        pageSizeOptions={[50, 100, 200]}
                        slots={{
                            loadingOverlay: LinearProgress,
                            toolbar: CustomToolbarA
                        }}
                        slotProps={{ toolbar: { csvOptions: { fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] } } }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        getCellClassName={(params) => {
                            if (params.field === 'city' || params.value == null) {
                                return '';
                            }
                            return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                        }}
                        processRowUpdate={params => handleEdit(params)}
                        onProcessRowUpdateError={err => console.log(err)}
                        onCellEditStop={params => setField(params.field)}
                    />
                </Box>
                <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%'}}>
                    <StripedDataGridPro
                        pagination
                        rows={sameDay}
                        columns={dispatchColumns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                            backgroundColor: '#D35858',
                            color: '#1a3e72',
                        },
                        [`.${gridClasses.cell}.hot`]: {
                            backgroundColor: '#A6D358',
                            color: '#1a3e72',
                        },}}
                        pageSizeOptions={[50, 100, 200]}
                        slots={{
                            loadingOverlay: LinearProgress,
                            toolbar: CustomToolbarB
                        }}
                        slotProps={{ toolbar: { csvOptions: { fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] } } }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        getCellClassName={(params) => {
                            if (params.field === 'city' || params.value == null) {
                                return '';
                            }
                            return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                        }}
                        processRowUpdate={params => handleEdit(params)}
                        onProcessRowUpdateError={err => console.log(err)}
                        onCellEditStop={params => setField(params.field)}
                    />
                </Box>
            </div>
        </div>
    );
};

export default Dispatch;