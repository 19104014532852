const dispatchColumns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150, editable: false },
    {
        field: 'name',
        headerName: 'Name',
        headerClassName: 'table-header',
        width: 250,
        editable: false,
    },
    {
        field: 'service_type',
        headerName: 'Type',
        headerClassName: 'table-header',
        width: 130,
        editable: false,
    },
    {
        field: 'route_code',
        headerName: 'Route No',
        headerClassName: 'table-header',
        width: 130,
        editable: true,
    },
    {
        field: 'wave_time',
        headerName: 'Wave',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'notes',
        headerName: '',
        headerClassName: 'table-header',
        width: 130,
        editable: true,
    },
]

export default dispatchColumns;