import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import authService from "../../services/auth.service";
import columns from "./sumColumns";
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { alpha, styled } from '@mui/material/styles';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BlurOnOutlinedIcon from '@mui/icons-material/BlurOnOutlined';
import BlurCircularOutlinedIcon from '@mui/icons-material/BlurCircularOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { isMobile } from 'react-device-detect';


const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
}));
export function CustomFooterStatusComponent(props) {
    return (
      <>
      </>
    );
  }

Date.prototype.getWeek = function (dowOffset) {
    dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0; 
    var newYear = new Date(this.getFullYear(),0,1);
    var day = newYear.getDay() - dowOffset; 
    day = (day >= 0 ? day : day + 7);
    var daynum = Math.floor((this.getTime() - newYear.getTime() - 
    (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
    var weeknum;
    if(day < 4) {
        weeknum = Math.floor((daynum+day-1)/7) + 1;
        if(weeknum > 52) {
            let nYear = new Date(this.getFullYear() + 1,0,1);
            let nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            weeknum = nday < 4 ? 1 : 53;
        }
    }
    else {
        weeknum = Math.floor((daynum+day-1)/7);
    }
    return weeknum;
};

const PaymentSummary = () =>{
    const name = authService.getCurrentUser()
    console.log(name)
    const [ dataRes, setDataRes ] = useState([])
    const [ content, setContent ] = useState([])
    const [ currentWeek, setCurrentWeek ] = useState()
    const [ openQuery, setOpenQuery ] = useState(false)
    const [ weekY, setWeekY ] = useState()
    const [ sk, setSk ] = useState()
    const [ phoneCut, setPhoneCut ] = useState()
    const [ details, setDetails ] = useState()
    const [ existingTickets, setExistingTickets ] = useState([])
    const [ invoiceFiles, setInvoiceFiles ] = useState([])
    const [ openPdf, setOpenPdf ] = useState(false)
    const [ pdfName, setPdfName ] = useState()
    const [ fileUrl, setFileUrl ] = useState()

    
    useEffect(()=>{
        UserService.getSinglePaymentData({name: name.username}).then(
            response =>{
                setDataRes(response.data)
            }
        ).catch(err => console.log(err.message))
        setCurrentWeek(new Date().getWeek())
        UserService.getAssociatesNamesSkno().then(
            response =>{
                const data = response.data
                console.log(response.data)
                data?.map(row =>{
                    if(row.name == name.username){
                        setSk(row.account_no)
                        setPhoneCut(row.phone.substr(7, 6))
                    }
                })
            }
        ).catch(err => console.log(err))
        UserService.getUserTickets({username: name.username}).then(
            response =>{
                setExistingTickets(response.data)
            }
        ).catch(err => console.log(err))
        UserService.getInvoiceFiles({name: name.username}).then(
            response =>{
                setInvoiceFiles(response.data)
            }
        ).catch(err => console.log(err))
    },[])

    useEffect(()=>{
        let yearWeek = []
        let years = []
        let yearsComp = []
        dataRes?.map(row =>{
            yearWeek.push(row.week_no)
            years.push(row.week_no.split('-')[0])
        })
       
        years = years.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
        })
        years.sort()
        years.reverse()
        yearWeek.sort()
        yearWeek.reverse()
        console.log(yearWeek)
        dataRes?.sort((a,b) => a.week_no.localeCompare(b.week_no))
        dataRes.reverse()
        yearWeek = yearWeek.filter((item, index) => {
            return yearWeek.indexOf(item) == index;
        })
        console.log(yearWeek)
        years?.forEach(item =>{
            let yearWeekComp = []
            yearWeek.forEach(weey =>{
                let accPlc = [] 
                let total = 0
                let check = false
                existingTickets?.map(ticket =>{
                    if(ticket.notes?.split(' - ').length > 2){
                        const dateYW = ticket.notes?.split(' - ')[2].split('-')[0]
                        const weeYk = weey.split('-')[0]+weey.split('-')[1]
                        console.log(dateYW+'----'+weeYk)
                        if(dateYW == weeYk){
                            check = true
                        }
                    }
                })


                dataRes?.map((row, index) =>{
                    if(row.week_no == weey && row.week_no.split('-')[0] == item){
                        accPlc.push({id: index, date: row.schedule_date, description: row.description, amount: Number(row.sub_total) < 0 ? '-£'+(row.sub_total*-1).toFixed(4) : '£'+Number(row.sub_total).toFixed(4)})
                        total += Number(row.sub_total)
                    }  
                })  
                accPlc.push({id: 1000, date: '', description: 'Total', amount: total < 0 ? '-£'+total.toFixed(4)*-1 : '£'+total.toFixed(4)})   
                if(weey.split('-')[0] == item){
                    let files = [<br></br>]
                    invoiceFiles?.map(file =>{
                        console.log(file+'  '+weey)
                        if(Number(file.split('-')[1]) == weey.split('-')[0]+weey.split('-')[1]){
                            
                            if(file.match('FS')){
                                files.push(
                                    <button class="buttonSkOn" style={{ marginLeft: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => handleOpenPfd(e, file)}>
                                        <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}><UploadFileOutlinedIcon />Fleet Statement</span>
                                        <div class="fill-container"></div>
                                    </button>
                                )
                            }else if(file.match('R')){
                                files.push(
                                    <button class="buttonSkOn" style={{ marginLeft: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => handleOpenPfd(e, file)}>
                                        <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}><UploadFileOutlinedIcon />Remittence</span>
                                        <div class="fill-container"></div>
                                    </button>
                                )
                            }else{
                                files.push(
                                    <button class="buttonSkOn" style={{ marginLeft: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => handleOpenPfd(e, file)}>
                                        <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}><UploadFileOutlinedIcon />Self Bill</span>
                                        <div class="fill-container"></div>
                                    </button>
                                )
                            }
                        }
                    })
                    yearWeekComp.push(  <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            Week {weey.split('-')[1]}{(currentWeek - Number(weey.split('-')[1])) < 3  && Number(weey.split('-')[0]) == new Date().getFullYear() ? <><AdjustOutlinedIcon style={{color: '#eb7a3e', marginLeft: '10%'}}/>  Outstanding</> : Number(weey.split('-')[0]) < new Date().getFullYear() && Number(weey.split('-')[1])-currentWeek > 49 ? <><AdjustOutlinedIcon style={{color: '#eb7a3e', marginLeft: '10%'}}/>  Outstanding</> : <><BlurCircularOutlinedIcon style={{color: '#33cc33', marginLeft: '10%'}}/>  Paid</>}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '-10%', marginTop:2 }}>
                                <StripedDataGridPro
                                rows={accPlc}
                                
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 100,
                                        },
                                    },
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                        },
                                    },
                                }}
                                sx={{fontSize: '9px', fontFamily: ''}}
                                pageSizeOptions={[100]}
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    footer: CustomFooterStatusComponent
                                }}
                                
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                }
                                disableColumnResize={true}
            
                                />
                            </Box>
                            {!check ?  /*<button class="buttonSkOn" style={{marginTop: '10px', marginLeft: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => handleOpenQuery(e, weey)}>
                                            <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Query Invoice</span>
                                            <div class="fill-container"></div>
                                        </button>*/
                                        <a href="https://t.me/SKSO_Payments_bot">
                                            <button class="buttonSkOn" style={{marginTop: '10px', marginLeft: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} >
                                                <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Query Invoice</span>
                                                <div class="fill-container"></div>
                                            </button>
                                        </a> : 
                            <p style={{marginTop: '20px'}}>Query already placed. You can find your query in Tickets-&gt;Place a ticket</p>}
                            <div className="additionalFiles">
                                {!check ? <p style={{marginTop: '20px'}}>Additional files</p> : ''}
                                {files}
                            </div>
                        </AccordionDetails>
                    </Accordion>)
                }
            })
            
            
            yearsComp.push( <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                //aria-controls="panel2-content"
                //id="panel2-header"
                >
                    {item}
                </AccordionSummary>
                <AccordionDetails>
                    {yearWeekComp}
                </AccordionDetails>
            </Accordion>)
        })
        setContent(yearsComp)
        
    },[dataRes])

    const handleOpenQuery = (e, week) =>{
        setWeekY(week)
        setOpenQuery(true)
    }

    const sendQuery = () =>{
        const data = {ticket_id: new Date().getTime(), username: name.username, department: name.department, to_department: 'Senior Management', category: 'Payment', notes: details || sk+' - '+name.username+' - '+weekY?.split('-')[0]+weekY?.split('-')[1]+'-'+phoneCut, status: 'Open'}
        UserService.postTicket(data).then(
            response =>{
                window.location.reload(false)
            }
        ).catch(err => console.log(err))
    }

    const handleOpenPfd = (e, element) =>{
        setOpenPdf(true)
        setPdfName(element)
    }
    useEffect(()=>{
        if(pdfName){
            console.log(pdfName)
            UserService.getDocuments({name: name.username, pdfName: pdfName}).then(
                response =>{
                    console.log(response)
                    const file = new Blob([response.data], {
                        type: "application/pdf"
                      });
                      //Build a URL from the file
                      setFileUrl(URL.createObjectURL(file));
                      //console.log(fileURL)
                }
            ).catch(error => {
                console.log(error);
            });
        }
    },[pdfName])

    const handleShowClosePdf = () =>{
        setOpenPdf(false)
    }
    return(
        <>
           {content}
           <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openQuery}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Details</label></DialogTitle>
                    <DialogContent>
                        <textarea rows={10} style={{width: '100%', border: '1px solid gray', marginLeft:'10px'}} onChange={e => setDetails(sk+' - '+name.username+' - '+weekY?.split('-')[0]+weekY?.split('-')[1]+'-'+phoneCut+': '+e.target.value)}></textarea>
                        
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={sendQuery} >
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Send query</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={e => setOpenQuery(false)} >
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Cancel</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openPdf}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{pdfName}</DialogTitle>
                    <DialogContent>
                        {isMobile ? '' :<iframe src={fileUrl} style={{width: '100%', height: '100%'}}/>}
                        {isMobile ? <a href={fileUrl} download>download</a> : ''}
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleShowClosePdf}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </>
    )
}
export default PaymentSummary;