import React from "react";
import { useEffect, useState } from "react";
import UserService from "../../services/user.service";
import columns from "./payment";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter} from '@mui/x-data-grid-pro';
import AuthService from "../../services/auth.service";

const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

const OsmPaymentDatabase = () =>{
    const [ paymentData, setPaymentData ] = useState([])
    const [ displayData, setDisplayData ] = useState([])
    const [ hidenColumns, setHidenColumns ] = useState({id: false, document_no: false, sk_no: false})
    const [ loading, setLoading ] = useState(false)
    const user = AuthService.getCurrentUser()
    const [ userDep, setUserDep ] = useState()
    const [ userRole, setUserRole ] = useState()

    useEffect(()=>{
        setLoading(true)
        UserService.getPaymentOsmDatabase().then(
            response =>{
                setPaymentData(response.data)
                setLoading(false)
            }
        ).catch(err =>console.log(err.message))
    },[])

    useEffect(()=>{
      UserService.getOsmAss({name: user.username}).then(
        response =>{
          const data = response.data
          setUserDep(data.station)
          setUserRole(data.skso_role)
          console.log(response.data)
        }
      ).catch(err =>{
        console.log(err)
      })
    }, [user])

    useEffect(() =>{
        let dataPlc = []
        paymentData?.map(row =>{
          if(userDep == row.station && userRole == 'OSM'){
            dataPlc.push({id: row.entry, document_no: row.document_no, category: row.category, sk_no: row.sk_no, associate_name: row.associate_name, schedule_date: row.schedule_date, description: row.description, duration: row.duration, route: row.route, rate: row.rate, qty: row.qty, sub_total: row.sub_total, vat: row.vat, week_no: row.week_no, station: row.station, notes: row.notes, updatedBy: row.updatedBy, orh_wave_time: row.orh_wave_time, orh_dispatch_time: row.orh_dispatch_time, orh_end_time: row.orh_end_time, orh_actual_duration: row.orh_actual_duration, orh_late_wave: row.orh_late_wave, orh_notes: row.orh_notes})
          }
        })   
        setDisplayData(dataPlc)
    },[paymentData])

    /*const handleSearch = (e) =>{
        let dataPlc = []
        paymentData?.map(row =>{
            if(row.associate_name?.toLowerCase().match(e.target.value?.toLowerCase())){
                dataPlc.push({id: row.entry, document_no: row.document_no, category: row.category, sk_no: row.sk_no, associate_name: row.associate_name, schedule_date: row.schedule_date, description: row.description, duration: row.duration, route: row.route, rate: row.rate, qty: row.qty, sub_total: row.sub_total, vat: row.vat, week_no: row.week_no, station: row.station, notes: row.notes, updatedBy: row.updatedBy, orh_wave_time: row.orh_wave_time, orh_dispatch_time: row.orh_dispatch_time, orh_end_time: row.orh_end_time, orh_actual_duration: row.orh_actual_duration, orh_late_wave: row.orh_late_wave, orh_notes: row.orh_notes})
            }
        })
        setDisplayData(dataPlc)   
    }*/

    return(
        <div className='dashArch'>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Payment Database (OSM)</h3>
            <Box sx={{ height: 'fit-content', width: 'fit-content !important', paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGridPro
                    pagination
                    //checkboxSelection
                    rows={displayData}
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 50,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[50]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    slotProps={{ toolbar: { csvOptions: { fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] } } }}
                    loading={loading}
                    //processRowUpdate={params =>handleCellEdit(params)}
                    //onRowSelectionModelChange={params => setSelectedIds(params)}
                    onRowClick={params => console.log(params)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    //onColumnVisibilityModelChange={param => setHidenColumns(param)}    
                    columnVisibilityModel={hidenColumns}
                    ignoreValueFormatterDuringExport
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                    {...displayData}
                />
            </Box> 
        </div>
    )
} 
export default OsmPaymentDatabase;