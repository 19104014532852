const pColumns = [
    { field: 'id', headerName: 'Line', headerClassName: 'table-header', width: 50 },
    {
        field: 'description',
        headerName: 'Description',
        headerClassName: 'table-header',
        width: 350,
        editable: false,
      },
    {
        field: 'rate',
        headerName: 'Rate',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
      },
      {
        field: 'week_no',
        headerName: 'Week No',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
      }
]
export default pColumns;